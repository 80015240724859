import * as React from 'react';

import Select, { SelectProps } from 'antd/lib/select';
import Empty from 'antd/lib/empty';

import Autocomplete, {
	AutocompleteProps,
	Option,
	OptionType,
} from '@common/react/components/Forms/Autocomplete/Autocomplete';

import { isFunction, isUndefined } from '@common/react/utils/guards';
import { debounce } from '@common/typescript/Utils';

import { WithId } from '@common/typescript/objects/WithId';
import SimpleLoader from '@common/react/components/UI/SimpleLoader/SimpleLoader';

const Loading: React.FC = () => {
	return <Empty
		image={Empty.PRESENTED_IMAGE_SIMPLE}
		description="Loading..."
		style={{ marginBlock: 8 }}
		imageStyle={{ height: 35 }}
	/>;
};

export interface MultiSelectAutoProps<T extends WithId = any> extends AutocompleteProps<T, Partial<SelectProps>>{
	items: Array<any>;
	loader?: React.ReactNode;
	notFoundContent?: React.ReactNode;
}

export default class MultiSelectAuto<T extends WithId = any>
	extends Autocomplete<T, Partial<SelectProps>, MultiSelectAutoProps<T>> {
	public static defaultProps: Partial<MultiSelectAutoProps> = {
		value: '',
		loaderMarkup: <SimpleLoader />,
		paramName: 'text',
		shouldSelectMatch: true,
	};

	constructor(props: MultiSelectAutoProps<T>) {
		super(props);

		const { renderOption, antdProps } = this.props;
		let items: Array<OptionType> = [];

		if (antdProps?.defaultValue) {
			const { defaultValue } = antdProps as any;
			items = defaultValue.map(renderOption || this.defaultRender);
		}

		this.state = {
			items,
			value: '',
			isLoading: false,
			loadedForParams: null,
			reload: false,
		};

		this.loadItems = debounce(this.loadItems.bind(this), 300);
		this.onSearchHandler = this.onSearchHandler.bind(this);
		this.defaultRender = this.defaultRender.bind(this);
		this.selectMatchingOption = this.selectMatchingOption.bind(this);
		this.onSelectFocus = this.onSelectFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps: AutocompleteProps) {
		if (nextProps.value !== this.props.value && !Array.isArray(nextProps.value)) {
			this.setState({
				value: nextProps.value,
			});
		}
	}

	onSelect = (value, option) => {
		this.props.onSelect && this.props.onSelect(value, option);

		this.loadItems('');
		/* min = 1;
		this.setState({
			isLoading: true
		});
		(value.length >= min) {
			this.setState({
				isLoading: true
			});
			this.loadItems(value);
		} else {
			this.setState({
				items: []
			});
		} */
		// this.onSearchHandler('');
	}

	onSelectFocus() {
		if (this.state.value === '') {
			this.onSearchHandler('');
		}
	}

	selectMatchingOption(items, value: string) {
		return undefined;
	}

	onBlur() {
		this.setState({
			isLoading: false,
			value: '',
		});
	}

	render(): JSX.Element {
		const {
			disabled, loaderMarkup, loader = <Loading />, loadOnFocus, placeholder, antdProps, notFoundContent,
		} = this.props;
		let defaultValue = antdProps?.defaultValue as any;

		if (defaultValue && Array.isArray(defaultValue)) {
			defaultValue = defaultValue.map(({ id }) => String(id));
		}

		return (
			<div className="autocomplete-filter-component">
				<div className={`autocomplete-component ${disabled ? 'autocomplete-component_disabled' : ''}`}>
					{this.state.isLoading && <div className="autocomplete-component__loader dropdown-not-show">{loaderMarkup}</div>}
					<Select
						{...antdProps}
						allowClear={antdProps?.allowClear ?? true}
						mode="multiple"
						className="pull-right multiple-select-filter"
						notFoundContent={this.state.isLoading ? loader : notFoundContent}
						onChange={this.onChange}
						disabled={disabled}
						onSelect={this.onSelect}
						onFocus={loadOnFocus ? this.onSelectFocus : undefined}
						placeholder={isUndefined(placeholder) ? 'Start typing for search...' : placeholder}
						onSearch={(value) => this.onSearchHandler(value)}
						filterOption={(inputValue, { props }: any) => {
							const { children } = props;
							return (typeof children === 'string' && children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1);
						}}
						defaultValue={defaultValue}
						onBlur={this.onBlur}
					>
						{this.state.items.map((option) => {
							const item = (option?.item || (option as any));
							const { renderTitle } = this.props;
							let title;
							if (isFunction(renderTitle)) {
								title = renderTitle(item);
							} else {
								title = `${item[(renderTitle || 'name')] || item?.title}`;
							}

							return <Option
								key={option.key || option.id || option.item?.id}
								value={`${option.key || option.id || option.item?.id}`}
								title={title}
								{...{ item }}
							>
								{option.label || option.value || title}
							</Option>;
						})}
					</Select>
				</div>
			</div>
		);
	}
}
