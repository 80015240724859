import { WithId } from '@common/typescript/objects/WithId';
import { WithFile } from '@common/typescript/objects/WithFile';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Nullable } from '@common/typescript/objects/Nullable';

import { BaseAppointment, BaseAppointmentProcedure } from '@commonTuna/react/objects/BaseAppointment';
import { AppointmentStatus as BaseAppointmentStatus } from '@commonTuna/react/objects/AppointmentStatus';
import { BaseInvite } from '@commonTuna/react/objects/BaseInvite';

import { Patient } from '@app/objects/Patient';
import { Doctor, DoctorProcedure } from '@app/objects/Doctor';
import { Location } from '@app/objects/Location';
import { User } from '@app/objects/User';
import { Disease } from '@app/objects/Disease';
import { Room } from '@app/objects/Room';
import { Procedure } from '@app/objects/Procedure';
import { PatientFile } from '@app/objects/PatientFile';
import { Review } from '@app/objects/Review';

export type AppointmentStatus = BaseAppointmentStatus;
export {
	AppointmentChronoStatus,
	SystemAppointmentStatus,
	AppointmentChronoStatusNames,
	SAStatusNames,
} from '@commonTuna/react/objects/AppointmentStatus';

export { SystemAppointmentType, SATypeNames } from '@commonTuna/react/objects/AppointmentType';
export type { AppointmentType } from '@commonTuna/react/objects/AppointmentType';

export interface ClinicalNote extends WithId {
	appointment: Appointment;
	appointmentId: number;

	locked: boolean;
	updated: number | null;
	path: string;
	files: Array<WithFile<ClinicalNote>>;
}

export enum AppointmentMode {
	Appointment,
	NotAppointment
}

export enum DayOffType {
	DayOff,
	Holiday,
	Vacation
}

export enum DayOffStatus {
	Request,
	Approved,
	Declined
}

export const DayOffStatusNames = {
	[DayOffStatus.Request]: 'Request',
	[DayOffStatus.Approved]: 'Approved',
	[DayOffStatus.Declined]: 'Declined',
};

export enum ReviewStatus {
	None,
	Pending,
	Send,
	Opened,
	Left,
	Published
}

export const ReviewStatusNames = {
	[ReviewStatus.None]: 'None',
	[ReviewStatus.Pending]: 'Pending',
	[ReviewStatus.Send]: 'Send',
	[ReviewStatus.Opened]: 'Opened',
	[ReviewStatus.Left]: 'Left',
	[ReviewStatus.Published]: 'Published',
};

export interface Appointment extends BaseAppointment {
	chronoId: number | null;

	patient: Patient;
	patientId: number | null;

	examRoom: number;

	room: Room | null;
	roomId: number | null;

	location: Location | null;
	locationId: number | null;

	doctorId: number | null;
	doctor: Doctor | null;

	procedure?: Procedure | null;
	procedureId?: number | null;

	signature: string;
	initials: string;
	witnessSignature: string;
	plan: string;

	appointmentMode: AppointmentMode;
	dayOffType: DayOffType;

	diseases: Array<AppointmentDisease>;
	procedures: Array<AppointmentProcedure>;
	clinicalNotes: Array<ClinicalNote>;

	userId: Nullable<number>;
	user: Nullable<User>;

	employeeId: Nullable<number>;
	employee: Nullable<User>;

	supervisorId: Nullable<number>;
	supervisor: Nullable<User>;

	dayOffStatus: DayOffStatus;

	invites: Array<BaseInvite>;

	childName: string;

	reviewStatus: ReviewStatus;

	review: Review;
}

export enum AppointmentLogType {
	StatusCreated,
	StatusChanged
}

export enum CaseType {
	BreastAugmentation = 0,
	Other = 1
}

export const AppointmentLogTypeNames = {
	[AppointmentLogType.StatusCreated]: 'Status Created',
	[AppointmentLogType.StatusChanged]: 'Status Changed',
};

export interface AppointmentLog extends WithDeleted {
	time: number;

	user: User | null;
	userId: number | null;

	appointment: Appointment;
	appointmentId: number;

	appointmentStatus: AppointmentStatus | null;
	appointmentStatusId: number | null;

	logType: AppointmentLogType;
}

export interface AppointmentDisease extends WithDeleted {
	disease: Disease;
	diseaseId: number;

	appointment: Appointment;
	appointmentId: number;
}

export enum AppointmentProcedureStatus {
	New,
	InProgress,
	Finished
}

export interface AppointmentProcedure extends BaseAppointmentProcedure {
	doctorProcedure: DoctorProcedure | null;
	doctorProcedureId: number;

	appointment: Appointment | null;
	appointmentId: number;

	room: Room | null;
	roomId: number;

	consents: Array<PatientFile>;

	count: number;
	disable: boolean;

	parent: AppointmentProcedure | null;
	parentId: number;

	status: AppointmentProcedureStatus;
}

export interface AppointmentProcedureDisease extends WithDeleted {
	disease: Disease | null;
	diseaseId: number;

	appointmentProcedure: AppointmentProcedure | null;
	appointmentProcedureId: number;
}
