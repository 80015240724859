import React from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import { Location } from 'history';
import Modal, { ModalFuncProps } from 'antd/lib/modal';

export interface LeaveConfirmationModalProps {
	handleBlockedNavigation: (nextLocation: Location) => boolean;
	when?: boolean;
	onOk: (leaveLocation: () => void) => void;
	message?: string;
	cancelText?: string;
	okText?: string;
	onCancel?: () => void;
	confirmProps?: Omit<ModalFuncProps, 'onOk' | 'onCancel' | 'content'>;
}

const LeaveConfirmationModal: React.FC<LeaveConfirmationModalProps> = ({
	when, handleBlockedNavigation, onOk, cancelText = 'No', okText = 'Yes', onCancel, ...rest
}) => {
	const [lastLocation, setLastLocation] = React.useState<Location | null>(null);
	const history = useHistory();
	const { message = 'Leave the page?' } = rest;

	return (
		<>
			<Prompt
				when={when}
				message={(location) => {
					if (lastLocation
						&& `${location.pathname}${location.search}` === `${lastLocation.pathname}${lastLocation.search}`) {
						setLastLocation(null);
						return true;
					}

					const res = handleBlockedNavigation(location);

					if (!res) {
						setLastLocation(location);
						const handleLeave = () => {
							location && history.push(`${location.pathname}${location.search}`);
						};

						Modal.confirm({
							onCancel: () => {
								handleLeave();
								onCancel && onCancel();
							},
							onOk: () => {
								onOk(handleLeave);
							},
							content: message,
							...rest.confirmProps,
							cancelText: 'No',
							okText: 'Yes',
						});
					}

					return res;
				}}
			/>
		</>
	);
};

export default LeaveConfirmationModal;
