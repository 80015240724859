import React from 'react';

import { InviteStatus } from '@commonTuna/react/objects/BaseInvite';

interface Props {
	status: InviteStatus;
	className?: string;
	size?: number;
	style?: React.CSSProperties;
}

const StatusIcon = {
	[InviteStatus.Done]: {
		color: '#38b848',
		icon: 'check',
		title: 'Done',
	},
	[InviteStatus.Send]: {
		color: 'red',
		icon: 'exclamation-circle',
		title: 'New',
	},
	[InviteStatus.Open]: {
		color: '#ffc100',
		icon: 'envelope-open-o',
		title: 'Open',
	},
};

const InviteStatusComponent: React.FC<Props> = ({
	status, className = '', size = 25, style = { verticalAlign: 'middle' },
}) => (
	<i
		className={`fa fa-${StatusIcon[status].icon} ${className}`}
		title={StatusIcon[status].title}
		style={{
			...style,
			fontSize: size,
			color: StatusIcon[status].color,
		}}
	/>
);

export default InviteStatusComponent;
