import * as React from 'react';

import { FormikProps } from 'formik';

interface ClearFieldsButtonProps {
	form: FormikProps<any> | null;
	nextValues: object;
	caption?: React.ReactNode | string;
	afterReset?: (data?: object) => void;
}

export const ClearFieldsButton: React.FC<ClearFieldsButtonProps> = ({
	form,
	caption,
	nextValues,
	afterReset,
}) => {
	const clearFields = (form: FormikProps<any> | null) => {
		form && form.resetForm(nextValues);
		afterReset && afterReset(nextValues);
	};

	return (
		<button
			type="button"
			className="btn btn-primary"
			title="Clear form fields"
			onClick={() => { clearFields(form); }}
		>
			{caption || <i className="fa fa-trash" />
			}
		</button>
	);
};
