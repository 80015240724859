import * as React from 'react';

import { request } from '@common/react/components/Api';
import File from '@common/react/components/Forms/Files/File';
import { FileType } from '@common/typescript/objects/FileInterface';
import { deleteConfirmation } from '@common/react/components/Modal/Modal';
import Colorbox from '@common/react/components/UI/Colorbox/Colorbox';

interface AvatarProps {
	type: string;
	object: any;
	update: (result: OnAvatarUploadResult, data?: any) => void;
	className?: string;
	buttonCaption?: string;
	infoMessage?: string;
	fileType?: FileType;
	property?: string;
	propertyOrigin?: string;
	confirmDelete?: boolean;
	additionalData?: object;
	noRelation?: boolean; // Whether to create relation for uploaded file or not
	onError?: (error: any) => void;
	removeRequest?: string;
	removeData?: any;
	customRequest?: (params: any) => Promise<any>;
}

export interface OnAvatarUploadResult {
	avatar: string;
	originalAvatar: string;
}

export interface FileAvatar {
	file: { src: string };
}

const AvatarWithColorbox: React.FC<AvatarProps> = (props) => {
	const {
		className = '',
		propertyOrigin = 'originalAvatar',
		removeData,
		object,
		type,
		removeRequest,
		onError,
		update,
		buttonCaption,
		infoMessage,
		fileType = FileType.Avatar,
		property = 'avatar',
		confirmDelete = true,
		additionalData,
		customRequest,
		noRelation = false,
	} = props;
	const [colorBoxIndex, setColorBoxIndex] = React.useState<number | null>(null);

	const removeFileUpload = () => {
		// this.setState({ isLoading: true });

		const data = removeData || {
			src: object[propertyOrigin],
			deleted: true,
			objectType: type,
			objectId: object.id,
		};

		request(removeRequest || 'uploadedFile', data)
			.then(() => {
				// set empty avatar in finally, so user can reload avatar even if something goes wrong
			})
			.catch((error) => {
				onError && onError(error);
			}).finally(() => {
				update({ avatar: '', originalAvatar: '' });
				// this.setState({ isLoading: false });
			});
	};

	const onConfirmDelete = () => {
		deleteConfirmation(() => {
			removeFileUpload();
		});
	};

	const onUpdate = (result: any) => {
		update({ avatar: result.thumb, originalAvatar: result.src }, result);
	};

	const openAvatar = () => {
		setColorBoxIndex(0);
	};

	const closeAvatar = () => {
		setColorBoxIndex(null);
	};

	const fileAvatar = [{ file: { src: object[propertyOrigin] } }];
	const fileSrc = object[propertyOrigin] || object[property];

	const Thumb = (<div className="thumbnail avatar-component__thumb">
		<img alt={property} src={object[property]} />
		<i
			className="avatar-component__remove fa fa-times"
			onClick={(e) => (confirmDelete ? onConfirmDelete() : removeFileUpload())}
		/>
		<i className="avatar-component__zoom fa fa-search" onClick={openAvatar} />
		{fileSrc ? (
			<a
				className="avatar-component__download"
				href={fileSrc}
				target="_blank"
				download
				title="Download"
				rel="noreferrer"
			>
				<i className="fa fa-download" />
			</a>
		) : null}
	</div>);

	return <div className="avatar-component">
		{object[property]
			? Thumb
			: (
				<File
					buttonCaption={buttonCaption}
					infoMessage={infoMessage}
					fileType={fileType}
					objectId={object.id}
					type={type}
					update={onUpdate}
					accept="image/png, image/jpeg, .heic"
					additionalData={additionalData}
					customRequest={customRequest}
					noRelation={noRelation}
					beforeRequest={(action, argument, setState) => {
						const { file } = argument;
						if (file.type.includes('image') || file.name.includes('.heic')) {
							action();
						} else {
							setState({
								isLoading: false,
								error: 'Error file type',
							});
						}
					}}
				/>
			)
		}
		<Colorbox files={fileAvatar} defaultIdx={colorBoxIndex} onCancel={closeAvatar} />
	</div>;
};

export default AvatarWithColorbox;
