import * as React from 'react';

import Modal from 'antd/lib/modal';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';

import { BaseInvite, InviteStatus } from '@commonTuna/react/objects/BaseInvite';
import '@commonTuna/react/scss/components/consentPdfModal.scss';

const params = {
	fallback: <Loader />,
};

const ConsentFormSwitcher = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ConsentFormSwitcher" */ '@commonTuna/react/components/UI/ConsentForm/ConsentFormSwitcher')), params);

interface ComponentProps {
	item: BaseInvite;
	onStatusChange?: () => void;
	patientId?: number;
	shouldSendInviteId?: boolean;
	enableSave?: boolean;
	visible?: boolean;
	setVisible?: (value: boolean) => void;
	afterSubmit?: () => void;
	afterCancelled?: () => void;
	nextBtn?: boolean;
	nextBtnOnClick?: () => void;
	showCloseButton?: boolean;
	closeAfterAccept?: boolean;
	customOpenBtnIcon?: React.ReactNode;
}

const ConsentPdfModal: React.FC<ComponentProps> = ({
	item,
	onStatusChange,
	patientId,
	shouldSendInviteId = false,
	enableSave = true,
	visible: propsVisible,
	setVisible: propsSetVisible,
	afterSubmit,
	afterCancelled,
	nextBtn,
	nextBtnOnClick,
	showCloseButton = true,
	closeAfterAccept = false,
	customOpenBtnIcon,
}) => {
	const hasSigned = React.useMemo(() => Boolean(item.patientFileId), [item.patientFileId]);

	const [showPdf, setShowPdf] = React.useState<boolean>(hasSigned);
	const [visible, setVisible] = React.useState(false);

	const queryParams = {
		inviteId: shouldSendInviteId ? item.id : 0,
		appointmentId: item.appointmentId,
		templateSnapshotId: item.templateSnapshotId,
		patientId,
		column: [{ caption: 'time', direction: 2 }],
	};

	const handleOpen = () => {
		if (propsSetVisible !== undefined) {
			propsSetVisible(true);
		} else {
			setVisible(true);
		}

		// waits for GetTemplateActor
		setTimeout(() => {
			if (onStatusChange && item.status === InviteStatus.Send) {
				// onStatusChange();
			}
		}, 1000);
	};

	const handleClose = (isAfterAccept?: boolean) => {
		if (propsSetVisible !== undefined) {
			propsSetVisible(false);
		} else {
			setVisible(false);
		}
		if (item.patientFileId && !showPdf) {
			setShowPdf(true);
		}

		if (onStatusChange && (item.status === InviteStatus.Send || isAfterAccept)) {
			onStatusChange();
		}
	};

	React.useEffect(() => {
		if (item.patientFileId) {
			setShowPdf(true);
		}
	}, [item.patientFileId]);

	const onAccept = () => {
		if (closeAfterAccept) {
			handleClose(true);
		} else {
			onStatusChange && onStatusChange();
		}
		afterSubmit && afterSubmit();
	};

	const editSignature = () => {
		setShowPdf(!showPdf);
	};

	const modalTitle = (title) => (
		<div className="consent-modal-title">
			{title}
			{item.patientFileId
				&& <span
					title="Edit"
					className="appointment-popup__edit appointment-popup appointment-popup__top-icon"
					onClick={() => editSignature()}
				>
					{showPdf ? <i className="fa fa-pencil" /> : <i className="fa fa-eye" />}
				</span>
			}
		</div>
	);

	const onCancel = () => {
		afterCancelled && afterCancelled();
		handleClose();
	};

	const handleNextBtn = () => {
		nextBtnOnClick && nextBtnOnClick();
		handleClose();
	};

	return (
		<>
			{(propsVisible === undefined || !propsSetVisible)
				&& <button type="button" className="btn btn-sm btn-default" title="Open" onClick={handleOpen}>
					{customOpenBtnIcon || <i className="fa fa-eye" />}
				</button>
			}
			<Modal
				title={modalTitle(item.templateSnapshot?.name || item.name || 'Description')}
				open={propsVisible !== undefined ? propsVisible : visible}
				onCancel={onCancel}
				footer={null}
				width={800}
				styles={{ body: { height: '80vh' } }}
				destroyOnClose
				maskClosable={false}
				rootClassName="consent-modal-root"
				className="consent-modal"
			>
				<div className="consent-modal__container">
					{item.templateSnapshot?.description
						? <div>{item.templateSnapshot.description}</div>
						: null
					}
					<ConsentFormSwitcher
						showPdf={showPdf}
						queryParams={queryParams}
						onAccept={onAccept}
						onCancel={onCancel}
						hasSigned={hasSigned}
						patientFileId={item.patientFileId}
						enableSave={enableSave}
						nextBtnOnClick={nextBtn ? handleNextBtn : undefined}
						showCloseButton={showCloseButton}
						readonly={item.answered && !item.patientFileId}
					/>
				</div>
			</Modal>
		</>
	);
};

export default ConsentPdfModal;
