import React from 'react';
import Modal from 'antd/lib/modal';

import moment from 'moment';

import { Review } from '@app/objects/Review';
import Stars from '@app/components/UI/Stars/Stars';

interface ReviewModalProps {
	review: Review;
	buttonClassName?: string;
}

const ReviewModal: React.FC<ReviewModalProps> = ({ review, buttonClassName }) => {
	const [visible, setVisible] = React.useState<boolean>(false);

	const close = () => setVisible(false);

	const open = () => setVisible(true);

	if (!review) return <> </>;

	return (
		<>
			<button type="button" onClick={open} className={`btn ${buttonClassName || ''}`}>
				My Review
			</button>
			<Modal
				open={visible}
				onCancel={close}
				title={`Review${review.titleEn ? ` - ${review.titleEn}` : ''}`}
				width={600}
				className="location-popap"
				footer={null}
			>
				<div key={review.id} className="review mb10">
					<div className="reviews-title">
						<div className="stars pull-left">
							<Stars value={review.rate} />
							<Stars value={5 - Math.round(review.rate)} className="star-disabled" />
						</div>
						<div className="pull-right">
							<strong className="mr10">{moment(review.time).fromNow()}</strong>
						</div>
					</div>
					<br />
					<p>{review.textEn}</p>
				</div>
			</Modal>
		</>
	);
};

export default ReviewModal;
