import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { IPublished } from '@common/typescript/objects/IPublished';
import { Nullable } from '@common/typescript/objects/Nullable';

export interface BaseDisease extends WithDeleted, IPublished {
	name: string;
	description: string;
	questions: Array<BaseQuestion>;

	global: boolean;
	alias: string;

	patientSign: boolean;

	questionsUpdated: Nullable<number>;
}

export enum QuestionType {
	Text = 1,
	YesNo = 2,
	Single = 3,
	Multiple = 4,
	Number = 5
}

export interface BaseQuestion extends WithDeleted, IPublished {
	disease: BaseDisease;
	diseaseId: Nullable<number>;
	text: string;
	questionType: QuestionType;
	answers: Array<BaseAnswer>;
}

export interface BaseAnswer extends WithDeleted, IPublished {
	question: BaseQuestion;
	questionId: Nullable<number>;
	text: string;
}
