import * as React from 'react';

import Popover, { PopoverProps } from 'antd/lib/popover';

import '@common/react/scss/components/filtersComponent.scss';

export interface IProps {
	open?: boolean;
	onOpenChange?: React.Dispatch<React.SetStateAction<boolean>>;
	filters: Array<() => boolean>;
	tooltipValues: JSX.Element;
	filtersClean?: () => void;
	apply?: boolean;
	disabled: boolean;
	popoverStyle?: React.CSSProperties;
	buttonClassName?: string;
	popoverClassName?: string;
	getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
	triggerNode?: React.ReactNode;
	title?: string;
	placement?: PopoverProps['placement'] | null;
}

const FiltersComponent: React.FC<IProps> = ({
	filters, tooltipValues, apply, filtersClean, disabled = false, open, onOpenChange, ...props
}) => {
	const {
		buttonClassName, popoverStyle, popoverClassName, getPopupContainer, triggerNode, title = 'Filters', placement = 'bottomRight',
	} = props;
	const [visible, setVisible] = React.useState<boolean>(false);
	const count = filters.reduce((res, filter) => (filter() ? res + 1 : res), 0);

	const onVisibleChange = React.useCallback((visible) => {
		setVisible(visible);
		onOpenChange && onOpenChange(visible);
	}, []);

	const clean = React.useCallback(() => {
		if (filtersClean) {
			filtersClean();
		}
	}, [filtersClean]);

	const close = React.useCallback(() => {
		setVisible(false);
		onOpenChange && onOpenChange(false);
	}, []);

	return (
		<div className="filters-component-container pull-right">
			{disabled
				? <>
					<button type="button" disabled={disabled} className="btn btn-default filters-component-button">
						<i className="fa fa-filter" aria-hidden="true" />
					</button>
				</>
				: (
					<Popover
						trigger="click"
						title={title}
						overlayStyle={popoverStyle}
						className={popoverClassName}
						open={open ?? visible}
						getPopupContainer={getPopupContainer}
						onOpenChange={onVisibleChange}
						content={
							<>
								{tooltipValues}
								{(apply || filtersClean)
									&& <div className="filters-apply-btn-container">
										{filtersClean
											? (
												<button
													type="button"
													className={`btn btn-danger ${apply ? 'mr10' : ''}`}
													disabled={count === 0}
													onClick={clean}
												>
													Clear
												</button>
											) : null
										}
										{apply
											? (
												<button
													type="button"
													className="btn btn-primary"
													onClick={close}
												>
													Apply
												</button>
											)
											: null
										}
									</div>
								}
							</>
						}
						overlayClassName={`filters-component-tooltip ${popoverClassName}`}
						placement={placement === null ? undefined : placement}
					>
						{triggerNode || <button
							type="button"
							className={`btn btn-default filters-component-button ${buttonClassName || ''}`}
						>
							<i className="fa fa-filter" aria-hidden="true" />
						</button>}
					</Popover>
				)}
			{count > 0 && !disabled
				&& <div
					className="badge dashboard-container-user-block__badge"
					onClick={clean}
				>
					<span>{count}</span>
					<i className="fa fa-close" />
				</div>
			}
		</div>
	);
};

export default FiltersComponent;
