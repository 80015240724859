import * as React from 'react';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';

import * as moment from 'moment';
import Select from 'antd/lib/select';

import 'react-datepicker/dist/react-datepicker.css';

import '@common/react/scss/components/datePickerReact.scss';

interface DatePickerAltProps {
	value: number | null;
	onChange: (date: number | null) => void;
	placeholder?: string;
	utc?: boolean;
	datePickerProps?: any;
	yearsSelectOptions?: Array<number>;
	useDefaultFormat?: boolean;
}

const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

const { Option } = Select;

const year = moment().year();

const getPopupContainer = (node) => node.closest('.react-datepicker') || document.body;

const years = Array.from({ length: 150 }).map((_, i) => year - 100 + i);

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const defaultRenderCustomHeader = (yearsSelectOptions: Array<number> = years) => ({
	date,
	changeYear,
	changeMonth,
	decreaseMonth,
	increaseMonth,
	prevMonthButtonDisabled,
	nextMonthButtonDisabled,
}) => (
	<div className="react-datepicker__header react-datepicker__custom-header">
		<div className="react-datepicker__current-month">
			{moment(date).format('MMMM YYYY')}
		</div>
		<button
			onClick={decreaseMonth}
			disabled={prevMonthButtonDisabled}
			type="button"
			className="react-datepicker__navigation react-datepicker__navigation--previous"
			aria-label="Previous Month"
		>
			<span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
				Previous Month
			</span>
		</button>
		<button
			onClick={increaseMonth}
			disabled={nextMonthButtonDisabled}
			type="button"
			className="react-datepicker__navigation react-datepicker__navigation--next react-datepicker__navigation--next--with-today-button"
			aria-label="Next Month"
		>
			<span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
				Next Month
			</span>
		</button>

		<Select
			className="react-datepicker__year-select"
			value={moment(date).year()}
			onChange={(value) => changeYear(value)}
			getPopupContainer={getPopupContainer}
		>
			{yearsSelectOptions.map((option) => (
				<Option key={option} value={option}>
					{option}
				</Option>
			))}
		</Select>
		<Select
			className="react-datepicker__month-select"
			value={moment(date).format('MMMM')}
			onChange={(value) => changeMonth(months.indexOf(value))}
			getPopupContainer={getPopupContainer}
		>
			{months.map((option) => (
				<Option key={option} value={option}>
					{option}
				</Option>
			))}
		</Select>
	</div>
);

export const getUtcDate = (time: number) => {
	const date = moment.utc(time).format('YYYY-MM-DD HH:mm:ss');
	const stillUtc = moment(date).toDate();
	return +moment.utc(stillUtc);
};

export const DatePickerReact: React.FC<DatePickerAltProps> = ({
	value,
	onChange,
	placeholder,
	utc,
	datePickerProps,
	yearsSelectOptions,
	useDefaultFormat,
}) => {
	const defaultValue = value !== null && value !== undefined ? utc ? moment(getUtcDate(value)) : moment(value) : undefined;

	const innerOnChange = (date: moment.Moment) => {
		onChange(date === null ? null
			: Math.ceil((+moment(date).endOf('day') + +moment(date).startOf('day')) / 2));
	};

	/* const format = React.useCallback((val) => {
		if (!val) return '';
		const len = val.length;

		if (len === 1 && !['0', '1'].includes(val[0])) {
			return '';
		} if (
			(len === 2 && val[0] !== '0' && !['0', '1', '2'].includes(val[1])) ||
			val.substr(0, 2) === '00'
		) {
			return val.substr(0, 1);
		}  if (len === 3) {
			if (val[2] === '/') {
				return val.substr(0, 2);
			}  if (
				(val.substr(0, 2) === '02' && ['0', '1', '2'].includes(val[2])) ||
				(val.substr(0, 2) !== '02' && ['0', '1', '2', '3'].includes(val[2]))
			) {
				return `${val.substr(0, 2)}/${val.substr(2)}`;
			} {
				return val.substr(0, 2);
			}
		} if (
			len === 5 &&
			(val.substr(3, 5) === '00' ||
				(val[3] === '3' && !['0', '1'].includes(val[4])) ||
				(val[3] === '3' && val[4] === '1' &&
					!['01', '03', '05', '07', '08', '10', '12'].includes(val.substr(0, 2)))
			)
		) {
			return val.substr(0, 4);
		} if (len === 6) {
			if (val[5] === '/') {
				return val.substr(0, 5);
			}
			return `${val.substr(0, 5)}/${val.substr(5)}`;
		} if (len > 10) {
			return val.substr(0, 10);
		}
		return val;
	}, []);

	const inputOnChange = React.useCallback((e) => {
		e.target.value = format(e.target.value);
	}, [format]); */

	const {
		showMonthDropdown, showYearDropdown, dropdownMode, maxDate,
	} = datePickerProps;

	const renderCustomHeader = React.useMemo(() => {
		return dropdownMode === 'select' ? (props) => defaultRenderCustomHeader(yearsSelectOptions)(props) : undefined;
	}, [dropdownMode, `${yearsSelectOptions}`]);

	return (
		<div className="date-picker-alt">
			<DatePicker
				selected={+(defaultValue || 0) || undefined}
				onChange={innerOnChange}
				value={defaultValue}
				// onChangeRaw={useDefaultFormat ? inputOnChange : undefined}
				todayButton="Today"
				isClearable
				placeholderText={placeholder || 'Select date'}
				maxDate={maxDate}
				autoComplete="off"
				customInput={<MaskedInput
					mask={dateMask}
					className="ant-input"
					guide
					id="date-picker"
				/>}
				{
					...{
						renderCustomHeader,
						...datePickerProps,
						showMonthDropdown,
						showYearDropdown,
					}
				}
			/>
			<i className="fa fa-calendar-o anticon-calendar" aria-hidden="true" />
		</div>
	);
};
// <i className="fa fa-calendar-o anticon anticon-calendar" aria-hidden="true"/>
