import * as React from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Yup from 'yup';

import { simpleStringValidator } from '@common/react/utils/validationHelpers';
import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';
import * as LoginState from '@common/react/store/Login';
import PhoneOrEmailInput, { emailOrPhoneValidator } from '@common/react/components/Forms/PhoneOrEmailInput/PhoneOrEmailInput';
import ServerPageProvider from '@common/react/components/Core/ServerPageProvider/ServerPageProvider';
import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { ItemEditor } from '@common/react/components/Core/ItemEditor/ItemEditor';
import { callWithConnectionOnce } from '@common/react/utils/configureSignarR';
import { WithId } from '@common/typescript/objects/WithId';
import SendCodeButton from '@common/react/components/Pages/Login/SendCodeButton';

import '@common/react/scss/components/login.scss';

export interface LoginFormValues extends WithId {
	login: string;
	password: string;
	code: string;
	path: string;
}

const validationSchema = Yup.object().shape({
	login: emailOrPhoneValidator,
	password: simpleStringValidator,
});

export interface AuthCodeComponentProps {
	formValues: LoginFormValues;
	afterSubmit: (item: LoginFormValues, res: any) => void;
	cancelAuthWithCode: () => void;
}

interface LoginProps<User extends BaseUser> {
	containerClassName?: string;
	afterBaseRedirectUser?: (user: User) => React.ReactNode;
	renderAuthWithCode?: (props: AuthCodeComponentProps) => React.ReactNode;
	logo?: React.ReactNode;
	withFieldTitle?: boolean;
	title?: string;
	withoutServerPage?: boolean;
	request?: string;
	afterSubmit?: (item: LoginFormValues, res: any) => void;
	onSaveRequestError?: ((error: string) => void);
	customReduxActions?: (dispatch, init: any) => void;
	buttons?: React.ReactNode;
	afterLogin?: () => void;
	recoverPath?: string;
	authByCodeRequest?: string;
	sendCodeRequest?: string;
}

const Login: <User extends BaseUser>(p: LoginProps<User>) => React.ReactElement<User> = <User extends BaseUser, >(props) => {
	const search = useLocation().search;

	const [isVisible, setIsVisible] = React.useState<boolean>(false);
	const [openLoginCode, setOpenLoginCode] = React.useState<boolean>(false);
	const [formValues, setFormValues] = React.useState<LoginFormValues | null>(null);

	const loginState: LoginState.LoginState<User> | null = useSelector((state: BaseApplicationState<User>) => state.login);
	const dispatch = useDispatch();
	const loginActions = bindActionCreators(LoginState.getActionCreators(), dispatch);

	const {
		afterBaseRedirectUser,
		renderAuthWithCode,
		withFieldTitle,
		containerClassName = '',
		logo,
		buttons,
		title = 'Login',
		withoutServerPage,
		request = 'auth',
		recoverPath = '/recover',
		afterSubmit = (item, res) => {
			const init = res?.initObject;

			if (init && init.user && init.guid) {
				!loginState.user && props.customReduxActions && props.customReduxActions(dispatch, init);

				loginActions.setUserAndSession(init.user as BaseUser, init.guid as string);
			}

			setFormValues(null);
		},
		onSaveRequestError = (error) => {
			if (error === '2FA enabled' && renderAuthWithCode) {
				setIsVisible(true);

				return true;
			}

			if (error === 'The confirmation code has been sent.') {
				setOpenLoginCode(true);

				return true;
			}
		},
		afterLogin,
		authByCodeRequest,
		sendCodeRequest,
	} = props;

	const [afterLoginTrigger, setLoginTrigger] = React.useState(false);

	React.useEffect(() => {
		if (loginState.user && !afterLoginTrigger) {
			afterLogin && afterLogin();
			setLoginTrigger(true);
		}
	}, [!!loginState.user]);

	const hostOptions = useSelector((state: BaseApplicationState<BaseUser>) => state.hostOptions.item);

	const redirectUser = React.useCallback((user: User) => {
		if (hostOptions?.redirectUrlName) {
			const redirectUrl = new URLSearchParams(search).get(hostOptions.redirectUrlName);
			if (redirectUrl) {
				return <Redirect to={redirectUrl} />;
			}
		}

		if (afterBaseRedirectUser) {
			return afterBaseRedirectUser(user);
		}

		return <Redirect to="/dashboard" />;
	}, [afterBaseRedirectUser]);

	const beforeSubmit = (values, actions, submit) => {
		setFormValues(values);

		callWithConnectionOnce(() => { submit(); });
	};

	const cancelAuthWithCode = () => {
		setIsVisible(false);
	};

	return (<>
		{!withoutServerPage
				&& <ServerPageProvider
					path="login"
					defaultTitle="Login"
					loader={<></>}
					inner={(serverPage) => <></>}
				/>
		}
		<div className={containerClassName}>
			{logo}
			<div className="enter-page__container">
				<ItemProvider<LoginFormValues>
					id={-1}
					type={request}
					readonly={false}
					add={{
						login: '',
						password: '',
						code: '',
						path: '/',
					}}
					validationSchema={validationSchema}
					onSaveRequestError={onSaveRequestError}
				>
					{renderAuthWithCode && isVisible && !loginState?.user
						? renderAuthWithCode({ formValues, afterSubmit, cancelAuthWithCode })
						: <div className="enter-page__form general-form">
							{loginState?.user && redirectUser(loginState.user)}
							{title && <div className="site-headline text-center">
								<h1>{title}</h1>
							</div>}
							<ItemEditor
								withButtons
								successMessage=""
								buttons={buttons}
								saveText="Login"
								beforeSubmit={beforeSubmit}
								afterSubmit={afterSubmit}
								edit={(formikBag) => <>
									<FormikField
										fieldName="login"
										title={withFieldTitle ? 'Email or Phone number*' : ''}
										containerClassName="form-group"
										render={(fieldProps, inputProps) => <>
											<PhoneOrEmailInput
												fieldProps={fieldProps}
												className="form-control"
												placeholder={withFieldTitle ? '' : 'Email or Phone number*'}
												{...inputProps}
											/>
											{authByCodeRequest && fieldProps.field.value
												&& <SendCodeButton
													phone={fieldProps.field.value}
													afterSubmit={afterSubmit}
													authRequest={authByCodeRequest}
													sendCodeRequest={sendCodeRequest}
													openModal={openLoginCode}
												/>
											}
										</>}
									/>
									<FormikField
										fieldName="password"
										title={withFieldTitle ? 'Password*' : ''}
										inputProps={{ placeholder: withFieldTitle ? '' : 'Password*', type: 'password' }}
										containerClassName="form-group"
									/>
									{recoverPath && <div className="clearfix mb10">
										<div className="pull-right">
											<Link to={recoverPath}>Forget Password?</Link>
										</div>
									</div>}
								</>}
							/>
						</div>
					}
				</ItemProvider>
			</div>
		</div>
	</>
	);
};

export default Login;
