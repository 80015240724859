import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import generatePicker, { PickerTimeProps, PickerDateProps, RangePickerDateProps } from 'antd/es/date-picker/generatePicker';

const DatePicker = generatePicker<Moment>(momentGenerateConfig);

export type TimePickerProps = Omit<PickerTimeProps<Moment>, 'picker'>
export type DatePickerProps = PickerDateProps<Moment>
export type RangePickerProps = RangePickerDateProps<Moment>

export default DatePicker;
