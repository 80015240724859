import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Nullable } from '@common/typescript/objects/Nullable';

export enum SystemAppointmentType {
	FollowUpConsultation = 0,
	InitialConsultation = 1,
	Surgery = 2,
	Telemedicine = 3,
	Triage = 4,
	Product = 5
}

export const SATypeNames = {
	[SystemAppointmentType.FollowUpConsultation]: 'Follow Up Consultation',
	[SystemAppointmentType.InitialConsultation]: 'Initial Consultation',
	[SystemAppointmentType.Surgery]: 'Surgery',
	[SystemAppointmentType.Telemedicine]: 'Telemedicine',
	[SystemAppointmentType.Triage]: 'Triage',
	[SystemAppointmentType.Product]: 'Product',
};

export interface AppointmentType extends WithDeleted {
	name: string;
	color: string;
	saType: Nullable<SystemAppointmentType>;

	sortOrder: number;
	showOnCalendar: boolean;
}
