import React from 'react';

import { BaseInvite } from '@commonTuna/react/objects/BaseInvite';

interface ComponentProps {
	invites: Array<BaseInvite>;
	setActiveInviteIndex: (index: number) => void;
	changeVisible: (visible: boolean) => void;
	setChangeVisibleFlag: (flag: boolean) => void;
	setNextBtn: (nxtBtn: boolean) => void;
	skipAnswered: (index: number) => void;
	startHidden?: boolean;
	btnClassName?: string;
}

const InvitesButtons: React.FC<ComponentProps> = ({
	invites,
	setActiveInviteIndex,
	changeVisible,
	setChangeVisibleFlag,
	setNextBtn,
	skipAnswered,
	startHidden = true,
	btnClassName,
}) => {
	return (<>
		{invites?.length > 0 && <>
			{startHidden && (invites.every((invite) => !invite.answered)
				? (
					<button
						type="button"
						className={btnClassName ? `${btnClassName}` : 'btn btn-primary'}
						onClick={() => {
							setActiveInviteIndex(0);
							changeVisible(true);
							setChangeVisibleFlag(true);
							setNextBtn(true);
						}}
					>
						Start Forms
					</button>
				)
				: (
					<button
						type="button"
						className={btnClassName ? `${btnClassName}` : 'btn btn-primary'}
						disabled={invites.every((invite) => invite.answered)}
						onClick={() => {
							skipAnswered(0);
							changeVisible(true);
							setChangeVisibleFlag(true);
							setNextBtn(true);
						}}
					>
						Resume Forms
					</button>
				))
			}
		</>}
	</>);
};

export default InvitesButtons;
