import * as React from 'react';

interface Props {
	title: string;
	value?: string | number | null | React.ReactElement;
	className?: string;
}

const ReadOnlyControlValue: React.FC<Props> = ({ title, className = 'form-group col-sm-6', value }) => {
	return <div className={className}>
		<label>{title}</label>
		<br />
		{value !== null && typeof value !== 'undefined' && value !== '' ? value : '-'}
	</div>;
};

export default ReadOnlyControlValue;
