import * as React from 'react';

import Modal from 'antd/lib/modal';
import Message from 'antd/lib/message';
import { FormikProps } from 'formik';

import { request } from '@common/react/components/Api';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';
import Button from '@common/react/components/Forms/Button';

import { BaseInvite, InviteStatus } from '@commonTuna/react/objects/BaseInvite';
import QuestionnaireForm from '@commonTuna/react/components/UI/QuestionnaireForm/QuestionnaireForm';
import { BaseTestSession, TestSessionButtons } from '@commonTuna/react/objects/BaseTestSession';
import QuestionnaireButtons from '@commonTuna/react/components/UI/QuestionnaireForm/QuestionnaireButtons';

import '@commonTuna/react/scss/components/questionnaireModal.scss';

interface ComponentProps {
	item: BaseInvite;
	patientId?: number;
	onStatusChange?: () => void;
	refreshAfterClose?: () => void;
	shouldSendInviteId?: boolean;
	enableSave?: boolean;
	showIsAnythingChangedModal?: boolean;
	visible?: boolean;
	setVisible?: (value: boolean) => void;
	afterSubmit?: () => void;
	afterRecreation?: () => void;
	afterUpdate?: () => void;
	afterCancelled?: () => void;
	afterSign?: () => void;
	afterCopy?: () => void;
	nextBtn?: boolean;
	nextBtnOnClick?: () => void;
	saveBtnCaption?: string;
	testSessionButtons?: Array<TestSessionButtons>;
	openBtnClassName?: string;
	openBtnTitle?: string;
	customOpenBtnIcon?: React.ReactNode;
	fromPortal?: boolean;
	readonly?: boolean;
}

const QuestionnaireModal: React.FC<ComponentProps> = ({
	item,
	onStatusChange,
	patientId,
	shouldSendInviteId = false,
	enableSave = true,
	showIsAnythingChangedModal = false,
	refreshAfterClose,
	visible: propsVisible,
	setVisible: propsSetVisible,
	afterSubmit,
	afterRecreation,
	afterUpdate,
	afterCancelled,
	afterSign,
	afterCopy,
	nextBtn = false,
	nextBtnOnClick,
	saveBtnCaption = 'Save',
	testSessionButtons = [],
	openBtnClassName = 'btn btn-sm btn-default',
	openBtnTitle = 'Open',
	customOpenBtnIcon,
	fromPortal,
	readonly,
}) => {
	const [session, setSession] = React.useState<BaseTestSession | null>(null);
	const [visible, setVisible] = React.useState(false);
	const [hasSign, setHasSign] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState(false);
	const [copyForwardDisabled, setCopyForwardDisabled] = React.useState<boolean>(false);
	const [signatureSrc, setSignatureSrc] = React.useState<string | undefined>('');
	const [form, setForm] = React.useState<FormikProps<any> | null>(null);

	React.useEffect(() => {
		if (propsVisible) {
			handleOpen();
		}
	}, [propsVisible]);

	const queryParams = {
		inviteId: shouldSendInviteId ? item.id : 0,
		appointmentId: item.appointmentId || null,
		diseaseId: item.diseaseId,
		patientId,
	};

	const handleOpen = () => {
		setLoading(true);
		request<BaseTestSession, BaseUser, BaseApplicationState<BaseUser>>('getTestSession', {
			...queryParams,
			withDisease: true,
		}).then((res) => {
			setSession(res);
			setSignatureSrc(res.files?.list[0]?.file?.src);
			setLoading(false);
			if (onStatusChange && item.status === InviteStatus.Send) {
				onStatusChange();
			}
			if (!res.oldTestSessionId || (res.oldTestSessionId <= 0)) {
				setCopyForwardDisabled(true);
			}
			if (showIsAnythingChangedModal && !item.askAlways && !res.answered && res.oldTestSessionId && res.oldTestSessionId > 0) {
				Modal.confirm({
					title: 'Is anything changed from your last visit?',
					wrapClassName: 'confirm-modal',
					content: '',
					okText: 'Yes',
					okType: 'danger',
					cancelText: 'No',
					onOk() {
						setVisible(true);
						setCopyForwardDisabled(true);
					},
					onCancel() {
						request<BaseTestSession, BaseUser, BaseApplicationState<BaseUser>>('questionnaireFormCopyForward', {
							appointmentId: res.appointmentId,
							oldTestSessionId: res.oldTestSessionId,
							diseaseId: res.diseaseId,
						})
							.then(() => {
								handleSubmit();
							}).catch((message) => {
								Message.error(message);
							});
					},
				});
			} else {
				setVisible(true);
			}
		}).catch((err) => {
			setCopyForwardDisabled(true);
			Message.error(err);
			setLoading(false);
		});
	};

	const handleClose = (): void => {
		if (propsSetVisible) {
			propsSetVisible(false);
		} else {
			setVisible(false);
		}
		refreshAfterClose && refreshAfterClose();
	};

	const handleSubmit = () => {
		if (onStatusChange && item.status !== InviteStatus.Done) {
			onStatusChange();
		}
		afterSubmit && afterSubmit();
		handleClose();
	};

	const onCancel = () => {
		afterCancelled && afterCancelled();
		handleClose();
	};

	const handleNextBtn = () => {
		nextBtnOnClick && nextBtnOnClick();
		handleClose();
	};

	return (
		<>
			{(propsVisible === undefined || !propsSetVisible)
				&& <Button isLoading={loading} type="button" className={openBtnClassName} title={openBtnTitle} onClick={handleOpen}>
					{customOpenBtnIcon || <i className="fa fa-eye" />}
				</Button>
			}
			<Modal
				title={item.disease?.name || item.name || 'Description'}
				open={propsVisible !== undefined ? propsVisible : visible}
				onCancel={onCancel}
				footer={null}
				width={900}
				destroyOnClose
				maskClosable={false}
			>
				<div className="row" style={{ paddingRight: '15px' }}>
					{session
						&& <QuestionnaireButtons
							readonly={readonly}
							testSession={session}
							testSessionButtons={testSessionButtons}
							signatureSrc={signatureSrc}
							setSignatureSrc={setSignatureSrc}
							setHasSign={setHasSign}
							copyForwardDisabled={copyForwardDisabled}
							setCopyForwardDisabled={setCopyForwardDisabled}
							form={form}
							updateTestSession={(testSession, updateSavedData) => {
								form?.setValues({ ...testSession });

								if (updateSavedData) {
									setSession({ ...testSession });
								}
							}}
							updateTestSessionAfterSign={false}
							afterRecreation={(res) => {
								afterRecreation && afterRecreation();
							}}
							afterUpdate={(res) => {
								afterUpdate && afterUpdate();
							}}
							afterSign={(res) => {
								afterSign && afterSign();
							}}
							afterCopy={(res) => {
								afterCopy && afterCopy();
							}}
							fromPortal={fromPortal}
						/>
					}
					{item.disease
						? <div className="col-sm-6">{item.disease.description}</div>
						: null
					}
				</div>
				<br />
				<QuestionnaireForm
					readonly={readonly}
					session={session}
					afterSubmit={handleSubmit}
					onCancel={onCancel}
					queryParams={queryParams}
					enableSave={!readonly && enableSave}
					hasSign={hasSign || Boolean(signatureSrc) || Boolean(!session?.disease?.patientSign)}
					nextBtnOnClick={nextBtn ? handleNextBtn : undefined}
					saveBtnCaption={saveBtnCaption}
					setSession={setSession}
					setHasSign={setHasSign}
					copyForwardDisabled={copyForwardDisabled}
					signatureSrc={signatureSrc}
					setSignatureSrc={setSignatureSrc}
					setForm={setForm}
					fromPortal={fromPortal}
					afterCopy={(res) => {
						afterCopy && afterCopy();
					}}
				/>
			</Modal>
		</>
	);
};

export default QuestionnaireModal;
