import React from 'react';

import * as Yup from 'yup';
import Alert from 'antd/lib/alert';
import Modal from 'antd/lib/modal';

import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { ItemEditor } from '@common/react/components/Core/ItemEditor/ItemEditor';
import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import { ConfirmationCodeInput } from '@common/react/components/Forms/ConfirmationCodeInput/ConfirmationCodeInput';
import { RequestButton } from '@common/react/components/UI/LoadingButton/LoadingButton';
import { phoneRegexp, simpleStringValidator } from '@common/react/utils/validationHelpers';

interface Props {
	phone: string;
	afterSubmit?: (res) => void;
	authRequest: string;
	sendCodeRequest: string;
	resendCodeRequest?: string;
	openModal?: boolean;
}

const validationSchemaConfirm = Yup.object().shape({
	loginCode: simpleStringValidator,
});

const SendCodeButton: React.FC<Props> = ({
	phone,
	afterSubmit,
	authRequest = 'authByCode',
	sendCodeRequest = 'sendLoginCode',
	openModal = false,
	resendCodeRequest = sendCodeRequest,
}) => {
	const [open, setOpen] = React.useState(openModal);
	const isPhoneValid = React.useMemo(() => {
		return phoneRegexp.test(phone || '');
	}, [phone]);

	const [time, setTime] = React.useState(30);
	const ref = React.useRef<any>(null);

	const startTimer = () => {
		ref.current = null;
		setTime(60);
		ref.current = setInterval(() => {
			setTime((prev) => {
				const newTime = prev - 1;
				if (!newTime && ref.current) {
					clearInterval(ref.current);
					ref.current = null;
				}
				return newTime;
			});
		}, 1000);
		return () => {
			if (ref.current) {
				clearInterval(ref.current);
			}
		};
	};

	React.useEffect(() => {
		startTimer();
	}, []);

	React.useEffect(() => {
		if (openModal && !open) {
			setOpen(openModal);
		}
	}, [openModal]);

	if (!isPhoneValid) return null;

	return <>
		<RequestButton
			requestType={sendCodeRequest}
			requestProps={{ phoneNumber: phone }}
			className="btn btn-sm btn-primary send-code-btn"
			onSuccess={(res) => {
				setOpen(true);
			}}
		>
			Send Code
		</RequestButton>
		<Modal
			title="Login Code"
			open={open}
			onCancel={() => {
				setOpen(false);
			}}
			destroyOnClose
			width={500}
			footer={null}
		>
			<div className="clearfix">
				<Alert
					className="pull-left"
					showIcon
					type="info"
					style={{ marginBottom: 10 }}
					message={<div style={{ display: 'inline-block', marginLeft: 5 }}>
						Security code has been sent to your phone number.
					</div>}
				/>
			</div>
			<div>
				Didn't receive a code?
				{' '}
				{time ? `Resend code in ${time} seconds.`
					: (
						<RequestButton
							className="resend-code-btn btn btn-default"
							beforeAction={() => {
								startTimer();
								return true;
							}}
							onError={(e) => {
								Modal.error({
									title: 'Error',
									content: e,
								});

								if (ref.current) {
									clearInterval(ref.current);
									ref.current = null;
									setTime(0);
									startTimer();
								}
							}}
							requestType={resendCodeRequest}
							requestProps={{ phoneNumber: phone }}
							successMessage="Security code has been successfully sent."
						>
							Resend code
						</RequestButton>)}
			</div>
			<ItemProvider<{ login, id, loginCode, path }>
				id={-1}
				add={{
					loginCode: '',
					login: phone,
					path: '/',
				}}
				type=""
				saveRequest={authRequest}
				validationSchema={validationSchemaConfirm}
			>
				<ItemEditor<{ id, loginCode, path }>
					withButtons
					readonly={false}
					formikProps={{ enableReinitialize: true }}
					successMessage=""
					afterSubmit={(values) => {
						setOpen(false);
						afterSubmit && afterSubmit({ phone });
					}}
					saveText="Send"
					edit={() => <div className="row">
						<FormikField
							containerClassName="col-sm-12 form-group mt10"
							fieldName="loginCode"
							title=""
							render={(fieldProps) =>
								<div className="text-center">
									<label htmlFor="loginCode">Code</label>
									<ConfirmationCodeInput
										fieldProps={fieldProps}
									/>
								</div>
							}
						/>
					</div>}
				/>
			</ItemProvider>
		</Modal>
	</>;
};

export default SendCodeButton;
