import * as React from 'react';

import { RequestButton } from '@common/react/components/UI/LoadingButton/LoadingButton';

import { BaseTestSession } from '@commonTuna/react/objects/BaseTestSession';

interface UpdateTestSessionButtonProps {
	testSession: BaseTestSession;
	hasSignature?: boolean;
	afterSubmit?: (testSession: BaseTestSession) => void;
	caption?: React.ReactNode | string;
}

export const UpdateTestSessionButton: React.FC<UpdateTestSessionButtonProps> = ({
	testSession,
	hasSignature,
	afterSubmit,
	caption,
}) => {
	return (
		<RequestButton
			requestType="updateTestSession"
			requestProps={{
				id: testSession.id,
			}}
			confirmationProps={!testSession.newQuestionsAdded && hasSignature
				? {
					title: 'Patient signature will be deleted. Are you sure?',
					content: '',
					okText: 'Yes',
					okType: 'danger',
					cancelText: 'Cancel',
				}
				: undefined
			}
			successMessage="Questionnaire was updated successfully!"
			onSuccess={(res: BaseTestSession, defaultOnSuccess) => {
				if (res) {
					afterSubmit && afterSubmit(res);
					defaultOnSuccess();
				}
			}}
			className={`btn ${testSession.newQuestionsAdded ? 'btn-success' : 'btn-danger'}`}
			title={`Add missing questions (it is ${testSession.newQuestionsAdded ? 'actual' : 'outdated'})`}
		>
			{caption || <i className="fa fa-plus" />}
		</RequestButton>
	);
};
