export const cropImage = (element: any) => {
	const ctx = element.canvas.drawing.getContext('2d');
	const width = ctx.canvas.width;
	const height = ctx.canvas.height;
	const imageData = ctx.getImageData(0, 0, width, height);
	const data = imageData.data;

	let minx = width;
	let maxx = 0;

	for (let y = 0; y < height; y++) {
		for (let x = 0; x < width; x++) {
			if (data[(width * y + x) * 4] > 0) {
				if (minx > x) {
					minx = x;
				}

				if (maxx < x) {
					maxx = x;
				}
			}
		}
	}

	const canvas = document.createElement('canvas');
	canvas.width = maxx - minx + 10 * 2;
	canvas.height = height;
	canvas.getContext('2d')?.putImageData(imageData, 10 - minx, 0);

	return canvas.toDataURL('image/png');
};

export const isCanvasBlank = (canvas: HTMLCanvasElement) => {
	return !canvas.getContext('2d')
		?.getImageData(0, 0, canvas.width, canvas.height).data
		.some((channel) => channel !== 0);
};

export const getDataUrl = (element: any) => {
	if (!element || isCanvasBlank(element.canvas.drawing)) {
		return null;
	}

	return cropImage(element);
};
