import React from 'react';

import { Rangepicker, RangepickerProps } from '@common/react/components/Forms/Datepicker/Datepicker';

import '@commonTuna/react/scss/components/rangepickerWithAdaptiveTooltip.scss';

interface Props extends RangepickerProps {
	mobileWidth?: number;
}

const RangepickerWithAdaptiveTooltip: React.FC<Props> = ({ mobileWidth = 768, ...rest }) => {
	const [isMobile, setIsMobile] = React.useState(false);

	React.useEffect(() => {
		const resize = () => {
			setIsMobile(window.innerWidth < mobileWidth);
		};

		resize();
		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	}, []);

	return <Rangepicker
		{...rest}
		antdProps={{
			...rest.antdProps,
			showTime: isMobile,
			popupClassName: 'datepicker-component-adaptive-tooltip',
		} as any}
		forceFormat={isMobile}
		format="MM/DD/YYYY"
	/>;
};

export default RangepickerWithAdaptiveTooltip;
