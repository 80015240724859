import * as React from 'react';

import * as moment from 'moment';

import { getPopupContainer } from '@common/react/components/Utils/Utils';
import DatePicker, { DatePickerProps, RangePickerProps } from '@common/react/components/Forms/Datepicker/CoreDatePicker';

const { RangePicker } = DatePicker;

const defaultDisabledDate = (currentDate?: moment.Moment) => {
	if (currentDate) {
		const dateUtc = currentDate.utc();
		return dateUtc.isBefore('0001-01-01') || dateUtc.isAfter('9999-12-31 23:59:59');
	}
	return false;
};

const disabledAfterToday = (currentDate?: moment.Moment) => (currentDate
	? currentDate.isBefore('0001-01-01') || currentDate.isAfter(moment(), 'day')
	: false);

const disabledBeforeToday = (currentDate?: moment.Moment) => (currentDate
	? currentDate.isBefore(moment(), 'day') || currentDate.isAfter('9999-12-31 23:59:59')
	: false);

export const disabledFrom = (from: number | null, withoutToday?: boolean) => (currentDate?: moment.Moment) => (
	currentDate
		? ((!withoutToday && currentDate.isBefore(moment(), 'day'))
			|| (from !== null ? currentDate.isBefore(moment(from), 'day') : false))
		: false
);

export const disabledTo = (to: number | null, withoutToday?: boolean) => (currentDate?: moment.Moment) => (
	currentDate
		? ((!withoutToday && currentDate.isAfter(moment(), 'day'))
			|| (to !== null ? currentDate.isAfter(moment(to), 'day') : false))
		: false
);

interface ComponentProps {
	format?: string;
	placeholderRange?: [string, string];
	disabledDate?: (currentDate?: moment.Moment) => boolean;
	maxDateToday?: boolean;
	minDateToday?: boolean;
	locale?: any;
	dateRender?: (current: moment.Moment, today: moment.Moment) => React.ReactNode;
	utc?: boolean;
}

export interface DatepickerProps extends ComponentProps {
	value: number | null;
	onChange: (date: number | null) => void;
	antdProps?: DatePickerProps & {showTime?: boolean};
}

export interface RangepickerProps extends ComponentProps {
	value: [number, number] | null;
	onChange: (date: [number, number] | null) => void;
	antdProps?: RangePickerProps & {showTime?: boolean};
	utcOffset?: string;
	forceFormat?: boolean;
}

const Datepicker: React.FC<DatepickerProps> = ({
	value, onChange, format = 'MM/DD/YYYY', maxDateToday, minDateToday, disabledDate, locale, antdProps = {}, dateRender, utc,
}) => {
	// tslint:disable-next-line:no-parameter-reassignment
	if (maxDateToday) disabledDate = disabledAfterToday;

	// tslint:disable-next-line:no-parameter-reassignment
	if (minDateToday) disabledDate = disabledBeforeToday;

	const defaultValue = value !== null && value !== undefined ? utc ? moment(value).utc() : moment(value) : undefined;

	const innerOnChange = (date: moment.Moment | null) => {
		onChange(date === null ? null : +date);
	};

	return <div className="datepicker-component">
		<DatePicker
			{...antdProps}
			format={antdProps && antdProps.showTime ? 'MM/DD/YYYY HH:mm' : format}
			onChange={innerOnChange}
			disabledDate={disabledDate}
			locale={locale}
			value={defaultValue}
			getPopupContainer={antdProps?.getPopupContainer || getPopupContainer}
			dateRender={dateRender}
		/>
	</div>;
};

export const Rangepicker: React.FC<RangepickerProps> = ({
	value,
	onChange,
	format = 'MM/DD/YYYY',
	placeholderRange = ['Start date', 'End date'],
	maxDateToday,
	minDateToday,
	disabledDate = defaultDisabledDate,
	locale,
	antdProps = {},
	utc = false,
	utcOffset = undefined,
	forceFormat,
}) => {
	const diff = utcOffset ? 60 * parseInt(utcOffset, 10) * 60 * 1000 : 0;
	const innerOnChange = (date: any) => {
		onChange(date?.length === 2 ? [
			utcOffset
				? moment(+date[0]).utc().startOf('day').valueOf() - diff
				: utc
					? moment(+date[0]).utc().startOf('day').valueOf()
					: moment(+date[0]).startOf('day').valueOf(),
			utcOffset
				? moment(+date[1]).utc().endOf('day').valueOf() - diff
				: utc
					? moment(+date[1]).utc().endOf('day').valueOf()
					: moment(+date[1]).endOf('day').valueOf(),
		] : null);
	};

	// tslint:disable-next-line:no-parameter-reassignment
	if (maxDateToday) disabledDate = disabledAfterToday;

	// tslint:disable-next-line:no-parameter-reassignment
	if (minDateToday) disabledDate = disabledBeforeToday;

	let defaultValue;

	if (value !== null && Number(value[0]) && Number(value[1])) {
		defaultValue = utcOffset
			? [moment(value[0] as number + diff).utc(), moment(value[1] as number + diff).utc()]
			: utc
				? [moment(value[0] as number).utc(), moment(value[1] as number).utc()]
				: [moment(value[0] as number), moment(value[1] as number)];
	} else {
		defaultValue = [null, null];
	}

	return <div className="datepicker-component">
		<RangePicker
			{...antdProps}
			format={antdProps && antdProps.showTime && !forceFormat ? 'MM/DD/YYYY HH:mm' : format}
			onChange={innerOnChange}
			disabledDate={disabledDate}
			locale={locale}
			value={defaultValue}
			getPopupContainer={antdProps?.getPopupContainer || getPopupContainer}
			placeholder={placeholderRange}
		/>
	</div>;
};

export default Datepicker;
