import React from 'react';

import message from 'antd/lib/message';

import CheckboxList from '@common/react/components/Core/CheckboxList/CheckboxList';
import { request } from '@common/react/components/Api';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';

import { BaseDoctorDisease } from '@commonTuna/react/objects/BaseDoctorDisease';
import '@commonTuna/react/scss/components/doctorDiseaseSettings.scss';

interface Props {
	diseaseId: number;
	doctorId: number;
}

const options = [
	{
		title: 'Auto copy forward',
		key: 'askAlways',
	},
	{
		title: 'For NorthernTuna Patient Portal',
		key: 'forPortal',
	},
];

const DoctorDiseaseSettings: React.FC<Props> = ({ diseaseId, doctorId }) => {
	const [doctorDisease, setDoctorDisease] = React.useState<BaseDoctorDisease>();
	const [values, setValues] = React.useState<Array<string>>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [open, setOpen] = React.useState(false);
	const [diseaseLoading, setDiseaseLoading] = React.useState(false);

	const updateDoctorDisease = (values) => {
		setLoading(true);
		request<BaseDoctorDisease, BaseUser, BaseApplicationState<BaseUser>>('doctorDisease', {
			doctorId,
			diseaseId,
			id: doctorDisease?.id,
			askAlways: !values.includes('askAlways'),
			forPortal: values.includes('forPortal'),
		})
			.then((res) => {
				setDoctorDisease({ ...doctorDisease, ...res });
				message.success('Successfully saved');
				setLoading(false);
			})
			.catch(message.error)
			.finally(() => setLoading(false));
	};

	const loadDoctorDisease = (load?: boolean) => {
		if (!doctorDisease || load) {
			setDiseaseLoading(true);
			request<BaseDoctorDisease, BaseUser, BaseApplicationState<BaseUser>>('getDoctorDisease', {
				doctorId,
				diseaseId,
			})
				.then((res) => {
					setDoctorDisease(res);
					setValues(options.filter(({ key }) => (key === 'askAlways' ? !res[key] : !!res[key])).map(({ key }) => key));
				})
				.catch((e) => {
					if (e === 'null result') {
						setDoctorDisease({ id: -1 } as BaseDoctorDisease);
						return;
					}
					message.error(e);
				})
				.finally(() => setDiseaseLoading(false));
		}
	};

	const handleChange = (values) => {
		setValues(values);
		updateDoctorDisease(values);
	};

	React.useEffect(() => {
		if (doctorDisease) {
			loadDoctorDisease(true);
		}
	}, [doctorId, diseaseId]);

	return <div className="doctor-disease-settings btn">
		<CheckboxList
			popoverProps={{
				placement: 'bottomLeft',
				open: !diseaseLoading && open,
				onOpenChange: (open) => {
					loadDoctorDisease();
					setOpen(open);
				},
			}}
			buttonIcon={loading || diseaseLoading ? <>&nbsp;</> : <i className="fa fa-cog" />}
			buttonClassName={`btn btn-default ${loading || diseaseLoading ? 'isLoading button-component' : ''}`}
			defaultValue={values}
			values={values}
			options={!doctorDisease ? [] : options.map(({ title, key }) => ({
				label: <span style={{ whiteSpace: 'nowrap' }}>{title}</span>,
				value: key,
			}))}
			onChange={handleChange}
		/>
	</div>;
};

export default DoctorDiseaseSettings;
