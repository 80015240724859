import * as React from 'react';

import { SAStatusNames, SystemAppointmentStatus } from '@commonTuna/react/objects/AppointmentStatus';

import { Appointment } from '@app/objects/Appointment';

export const SAStatusolors = {
	[SystemAppointmentStatus.Unconfirmed]: '#bbb',
	[SystemAppointmentStatus.Confirmed]: '#2cee7c',
	[SystemAppointmentStatus.InProgress]: '#91d3ff',
	[SystemAppointmentStatus.Completed]: '#448a61',
	[SystemAppointmentStatus.Missed]: '#f3a70d',
	[SystemAppointmentStatus.Rescheduled]: '#f00',
	[SystemAppointmentStatus.Canceled]: '#f00',
	[SystemAppointmentStatus.MessageLeft]: '#f9a839',
	[SystemAppointmentStatus.Closed]: '#099a11',
};

export const getContrast = (hexcolor) => {
	let hex = hexcolor;

	// If a leading # is provided, remove it
	if (hex.slice(0, 1) === '#') {
		hex = hexcolor.slice(1);
	}

	// If a three-character hexcode, make six-character
	if (hex.length === 3) {
		hex = hexcolor.split('').map((hex) => hex + hex).join('');
	}

	// Convert to RGB value
	const r = parseInt(hex.substr(0, 2), 16);
	const g = parseInt(hex.substr(2, 2), 16);
	const b = parseInt(hex.substr(4, 2), 16);

	// Get YIQ ratio
	const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

	// Check contrast
	return (yiq >= 128) ? '#000' : '#fff';
};

const StatusColor: React.FC<{ appointment: Appointment, className?: string}> = ({
	appointment, className = '',
}) => {
	const status = appointment.status;
	const statusColor = SAStatusolors[status?.saStatus ?? ''];

	const statusStyle = statusColor ? {
		backgroundColor: statusColor,
		color: getContrast(statusColor),
	} : {};

	return (
		<span className={`custom-event-status ignore-select-slot ${className}`} style={statusStyle}>
			{SAStatusNames[status?.saStatus || '0']}
		</span>
	);
};

export default StatusColor;
