import React from 'react';

import Message from 'antd/lib/message';

import { RequestButton } from '@common/react/components/UI/LoadingButton/LoadingButton';

import { BaseTestSession } from '@commonTuna/react/objects/BaseTestSession';

interface ComponentProps {
	session: BaseTestSession;
	afterCopy?: (res: BaseTestSession) => void;
	caption?: any;
	btnClassName?: string;
	disabled?: boolean;
}

const CopyForward: React.FC<ComponentProps> = ({
	afterCopy,
	session,
	caption,
	btnClassName,
	disabled,
}) => {
	return <RequestButton
		requestType="questionnaireFormCopyForward"
		type="button"
		requestProps={{
			appointmentId: session.appointmentId,
			diseaseId: session.diseaseId,
			oldTestSessionId: session.oldTestSessionId,
		}}
		onSuccess={(res: any) => {
			Message.success('Answers successfully copied!');
			res && afterCopy && afterCopy(res);
		}}
		onError={Message.error}
		className={btnClassName || 'btn btn-primary'}
		title="Copy forward"
		disabled={(session && session.askAlways) || disabled}
	>
		{caption || <i className="fa fa-clone" />}
	</RequestButton>;
};

export default CopyForward;
