import React from 'react';
import { useHistory } from 'react-router-dom';

import { RequestButton } from '@common/react/components/UI/LoadingButton/LoadingButton';

import { User } from '@app/objects/User';

interface ComponentProps {
	user: User | null;
	locationId: number;
	locationName: string;
	children: any;
	className?: string;
}

interface SupportChatResult {
	locationId: number;
	locationName: string;
	id: number;
}

export const SupportButton: React.FC<ComponentProps> = ({
	user,
	locationId,
	locationName,
	children,
	className,
}) => {
	const history = useHistory();

	return <>
		<RequestButton<SupportChatResult>
			requestType="supportChat"
			disabled={!user || locationId < 0}
			requestProps={{
				locationId,
				locationName,
			}}
			onSuccess={(res) => {
				history.replace({
					pathname: '/chats',
					search: `?chatId=${res.id}`,
				});
			}}
			onError={() => {
				history.push('/contact-support');
			}}
			type="button"
			className={className || 'btn btn-primary'}
			title="Open support chat"
		>
			{children}
		</RequestButton>
	</>;
};
