import * as React from 'react';

import { RequestButton } from '@common/react/components/UI/LoadingButton/LoadingButton';

import { BaseTestSession } from '@commonTuna/react/objects/BaseTestSession';

interface RecreateTestSessionButtonProps {
	testSession: BaseTestSession;
	afterRecreation?: (testSession: BaseTestSession) => void;
	caption?: React.ReactNode | string;
}

export const RecreateTestSessionButton: React.FC<RecreateTestSessionButtonProps> = ({
	testSession,
	afterRecreation,
	caption,
}) => {
	return (
		<RequestButton
			requestType="recreateTestSession"
			requestProps={{
				id: testSession.id,
			}}
			confirmationProps={{
				title: 'This questionnaire will be permanently deleted and replaced by new one. Are you sure?',
				content: '',
				okText: 'Yes',
				okType: 'danger',
				cancelText: 'Cancel',
			}}
			successMessage="Questionnaire was recreated successfully!"
			onSuccess={(res: BaseTestSession, defaultOnSuccess) => {
				if (res) {
					afterRecreation && afterRecreation(res);
					defaultOnSuccess();
				}
			}}
			className={`btn ${testSession.questionsUpdated ? 'btn-success' : 'btn-danger'}`}
			title={`Recreate questionnaire (it is ${testSession.questionsUpdated ? 'actual' : 'outdated'})`}
			showActionErrorMessage
		>
			{caption || <i className="fa fa-refresh" />}
		</RequestButton>
	);
};
