import * as React from 'react';

import { HandleChangeEventElement } from '@common/react/components/Core/ItemsProvider/ItemsProvider';

export interface ComponentProps<T> {
	onChange?: (evt: HandleChangeEventElement) => void;
	onSubmit?: (evt: HandleChangeEventElement) => void;
	name?: string;
	className?: string;
	placeholder?: string;
	withoutForm?: boolean;
	buttonType?: React.ButtonHTMLAttributes<T>['type'];
	buttonContent?: string | JSX.Element;
	submitOnClear?: boolean;
	containerClassName?: string;
	defaultValue?: string;
	dataParam?: string;
	value?: string;
	otherInputProps?: T;
	inputComponent?: any;
}

const SimpleSearchInput = <T extends React.HTMLProps<HTMLInputElement>>(props: ComponentProps<T>) => {
	const {
		className = 'form-control',
		placeholder = 'Search',
		withoutForm = false,
		buttonType = 'submit',
		submitOnClear = true,
		defaultValue,
		value: valueFromProps,
		onChange,
		onSubmit,
		name = 'text',
		containerClassName = '',
		buttonContent,
		dataParam,
		inputComponent: InputComponent,
		otherInputProps,
	} = props;
	const buttonRef = React.useRef<HTMLButtonElement>(null);
	const [value, setValue] = React.useState<string>(defaultValue || '');

	React.useEffect(() => {
		if (valueFromProps !== undefined && valueFromProps !== value) {
			setValue(valueFromProps);
		}
	}, [valueFromProps]);

	const handleChange = (evt: React.FormEvent<HTMLInputElement>): void => {
		setValue(evt.currentTarget.value);

		onChange && onChange(evt);
	};

	const onKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>): void => {
		if (evt && evt.key === 'Enter') {
			onSubmit && onSubmit({
				currentTarget: { name, value },
			});
		}
	};

	const onClick = (): void => {
		onSubmit && onSubmit({
			currentTarget: { name, value },
		});
	};

	const clearField = (): void => {
		const fakeTarget = {
			currentTarget: { name, value: '' },
		};

		setValue('');

		onChange && onChange(fakeTarget);

		if (submitOnClear) {
			if (onSubmit && withoutForm) {
				onSubmit(fakeTarget);
			} else if (buttonRef.current) {
				buttonRef.current.click();
			}
		}
	};

	const inputProps = {
		type: 'text',
		className,
		placeholder,
		name,
		onChange: handleChange,
		value,
		onKeyPress: withoutForm ? onKeyPress : undefined,
	};

	return <div className={`simple-search-component input-group ${containerClassName}`} data-param={dataParam}>
		{InputComponent ? <InputComponent {...otherInputProps} {...inputProps} /> : <input {...otherInputProps} {...inputProps} />}
		{value ? <i className="fa fa-times simple-search-component__clear" onClick={clearField} /> : null}
		<div className="input-group-btn">
			<button
				type={buttonType === 'submit' ? 'submit' : 'button'}
				onClick={withoutForm ? onClick : undefined}
				className="btn btn-sm btn-primary"
				ref={buttonRef}
			>
				{buttonContent
				?? <i className="fa fa-search" />
				}
			</button>
		</div>
	</div>;
};

export default SimpleSearchInput;
