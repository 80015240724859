import React from 'react';

import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import ResizeObserverContainer from '@common/react/components/UI/ResizeObserverContainer/ResizeObserverContainer';
import ButtonsDropdown from '@common/react/components/UI/ButtonsDropdown/ButtonsDropdown';

import '@common/react/scss/components/tableButtons.scss';

interface Props<T extends WithDeleted = WithDeleted> {
	record: T;
	buttons: Array<{visible: boolean, node}>;
}

const moreTagWidth = 34;
const padding = 10;

const TableButtons: React.FC<Props> = ({ record, buttons }) => {
	const [count, setCount] = React.useState(() => buttons.filter((item) => item.visible).length);
	const tagsWidth = React.useRef<any>();

	const onResize = ({ width, ref }) => {
		if (!tagsWidth.current) {
			tagsWidth.current = [];
			let elementChild = ref.current?.firstElementChild;
			while (elementChild) {
				tagsWidth.current.push(elementChild.getBoundingClientRect().width);
				elementChild = elementChild.nextElementSibling;
			}
		}
		if (tagsWidth.current) {
			let elementsWidth = tagsWidth.current
				.reduce((acc, width, i) => width + (i + 1 !== tagsWidth.current.length ? padding : 0) + acc, 0) > width ? moreTagWidth : 0;
			let i;
			for (i = 0; i < tagsWidth.current.length; i++) {
				elementsWidth += tagsWidth.current[i] + (i + 1 !== tagsWidth.current.length ? padding : 0);
				if (width < elementsWidth) break;
			}
			setCount(i);
		}
	};

	const nodes = buttons.filter((item) => item.visible).map((item) => item.node);

	return (
		<ResizeObserverContainer
			onResize={onResize}
			className="table-buttons"
		>
			{
				count < nodes.length - 1
					? (
						<>
							{nodes.slice(0, count).map((item) => item)}
							{!!nodes.slice(count).length
						&& (
							<ButtonsDropdown
								placement="bottomLeft"
								getPopupContainer={(node) => node.closest('.dashboard-container') || document.body}
								key="dropdown"
								dropdownValue={(
									<div data-id={`table-dropdown-${record.id}`} className="table-dropdown">
										{nodes.slice(count).map((item) => item)}
									</div>
								)}
							>
								<button type="button" data-action="Dropdown" className="btn btn-sm btm-default">
									<i className="fa fa-cog" />
								</button>
							</ButtonsDropdown>
						)}
						</>
					) : nodes.map((item) => item)
			}
		</ResizeObserverContainer>
	);
};

export default TableButtons;
