import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Select, { SelectProps } from 'antd/lib/select';

import SelectFilter from '@common/react/components/Forms/SelectFilter/SelectFilter';

import { ApplicationState } from '@app/store';

interface ComponentProps {
	additionalParams: any;
	onChange: (evt: any) => void;
	className?: string;
	selectProps?: SelectProps;
}

const Option = Select.Option;

const removeDuplicates = (arr) => {
	return arr?.filter((obj, pos, self) => self.findIndex((o) => o?.locationId === obj?.locationId) === pos) || [];
};

const PatientLocationFilter: React.FC<ComponentProps> = ({
	additionalParams,
	onChange,
	className = 'pull-right ml10',
	selectProps,
}) => {
	const patientLocations = useSelector((state: ApplicationState) => state.login.user?.patientLocations, shallowEqual);
	const resultLocations = removeDuplicates(patientLocations);
	return <>
		{resultLocations && resultLocations.length > 0
			&& <SelectFilter
				additionalParams={additionalParams}
				param="locationId"
				onChange={onChange}
				currentValue={resultLocations.find((value) => value.id === +additionalParams.locationId)?.locationName}
				defaultValue="All Locations"
				className={className}
				disabled={resultLocations.length < 2}
				selectProps={selectProps}
			>
				<Option value={-1}>All Locations</Option>
				{resultLocations.map((item) => <Option key={item.id} value={item.locationId ?? undefined}>
					{item.parentId
						? (<>
							<i className="fa fa-child" aria-hidden="true" />
							{' '}
						</>)
						: null}
					{item.locationName}
				</Option>)}
			</SelectFilter>
		}
	</>;
};

export default PatientLocationFilter;
