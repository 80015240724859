import * as React from 'react';

import {
	ItemsProviderContextState,
	useItemsProviderContext,
	WithKey,
} from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import { useItemProviderContext } from '@common/react/components/Core/ItemProvider/ItemProvider';
import useRequest from '@common/react/hooks/useRequest';

interface RenderProps<T extends WithKey> {
	state: ItemsProviderContextState<T>;
	actions: any;
	keys: any;
	setKeys: React.Dispatch<any>;
	values: any;
	setValues: React.Dispatch<any>;
}

interface BaseFilterComponentProps<T extends WithKey> {
	render: (values: RenderProps<T>) => React.ReactNode;
	getInitValues?: (sspContextState) => any;
	initValues?: any;
	loadFiltersRequest?: string;
	onFilterLoadError?: (e) => void;
}

const defaultGetInitValues = (context) => context?.state?.item?.filterProps;

const BaseFilterComponent: <T extends WithKey>(p: BaseFilterComponentProps<T>) => React.ReactElement<T> = <T extends WithKey, >(props) => {
	const {
		initValues: initValuesProps, getInitValues = defaultGetInitValues, loadFiltersRequest, render,
	} = props;

	const request = useRequest();
	const context = useItemsProviderContext<T>();

	const { state, actions } = context;
	const sspContext = useItemProviderContext<any>(false);

	const [keys, setKeys] = React.useState<any>({ key: 'default' });
	const [values, setValues] = React.useState<any>(() => initValuesProps || getInitValues(sspContext));

	React.useEffect(() => {
		if (!values && loadFiltersRequest) {
			request<T>(loadFiltersRequest, state.filters)
				.then((res: any) => {
					setValues((prev) => ({ ...prev, ...res }));
					setKeys({ key: 'loadValues' });
				})
				.catch((e) => props.onFilterLoadError && props.onFilterLoadError(e));
		}
	}, []);

	return (
		<>
			{render({
				state,
				actions,
				keys,
				setKeys,
				values,
				setValues,
			})}
		</>
	);
};

export default BaseFilterComponent;
