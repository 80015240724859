import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { WithTime } from '@common/typescript/objects/WithTime';
import { Nullable } from '@common/typescript/objects/Nullable';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { WithFile } from '@common/typescript/objects/WithFile';
import { List } from '@common/typescript/objects/List';

import {
	BaseAnswer, BaseDisease, BaseQuestion, QuestionType,
} from '@commonTuna/react/objects/BaseDisease';
import { BaseAppointment, BaseAppointmentProcedure } from '@commonTuna/react/objects/BaseAppointment';

export enum TestSessionButtons {
	DiseaseEditor = 0,
	Recreate = 1,
	Update = 2,
	Clear = 3,
	DoctorDiseaseSettings = 4
}

export interface BaseTestSession extends WithDeleted, WithTime {
	userId: number;
	user: Nullable<BaseUser>;

	diseaseId: number;
	disease: Nullable<BaseDisease>;

	questions: Array<BaseTestAnswer>;

	appointmentId: number;
	appointment: BaseAppointment | null;

	appointmentProcedureId: number | null;
	appointmentProcedure: BaseAppointmentProcedure | null;

	askAlways: boolean;
	answered: boolean;

	questionsUpdated: boolean;
	newQuestionsAdded: boolean;

	files: List<WithFile<BaseTestSession>>;

	oldTestSessionId: number | null;
}

export interface BaseTestAnswer extends WithDeleted, WithTime {
	testSession: BaseTestSession | null;
	testSessionId: number;

	questionId: number | null;
	question: BaseQuestion | null;
	questionType: QuestionType;
	questionText: string;

	answerText: string;
	answerBool: boolean;
	answerNumber: string;

	choices: Array<BaseTestChoice>;

	note: string;
}

export interface BaseTestChoice extends WithDeleted {
	testAnswer: BaseTestAnswer | null;
	testAnswerId: number;

	answer: BaseAnswer | null;
	answerId: number | null;
	answerText: string;

	selected: boolean;
}
