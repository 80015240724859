import * as React from 'react';

import Popover from 'antd/lib/popover';

import { AnswersHistory } from '@commonTuna/react/components/UI/AnswersHistory/AnswersHistory';
import { BasePatient } from '@commonTuna/react/objects/BasePatient';

interface ComponentProps {
	patient?: BasePatient;
	questionId: number;
}

export const AnswersHistoryButton: React.FC<ComponentProps> = ({
	patient,
	questionId,
}) => {
	const [tempPatient, setTempPatient] = React.useState<BasePatient | null>(patient || null);

	const updateTempPatient = (value: BasePatient | null) => {
		setTempPatient(value);
	};

	return (
		<Popover
			content={
				<AnswersHistory
					tempPatient={tempPatient}
					updateTempPatient={updateTempPatient}
					questionId={questionId}
				/>
			}
			onOpenChange={(visible) => {
				if (visible) {
					updateTempPatient(patient || null);
				}
			}}
			placement="leftTop"
			trigger="click"
		>
			<button
				type="button"
				className="btn btn-sm btn-primary"
			>
				<i className="fa fa-bars" />
			</button>
		</Popover>
	);
};
