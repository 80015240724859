import * as React from 'react';

import Modal, { ModalFuncProps } from 'antd/lib/modal';
import { fetch } from 'domain-task';

import Button, { ButtonProps } from '@common/react/components/Forms/Button';
import UseRequest from '@common/react/hooks/useRequest';

export enum FetchMethod {
	POST,
	GET
}

export interface LoadingButtonProps<T> extends ButtonProps {
	actionFunction: () => Promise<T>;
	children: React.ReactNode;
	/*
	 * if we don't have it or it return true actionFunction will be called
	 */
	beforeAction?: (action, event: React.MouseEvent<HTMLButtonElement>) => boolean;
	successMessage?: string;
	showActionErrorMessage?: boolean;
	onSuccess?: (res: T, baseSuccess: () => void) => void;
	onError?: (err: string) => void;
}

export interface RequestButtonProps<TResponse> extends Omit<LoadingButtonProps<TResponse>, 'actionFunction'> {
	requestType: string;
	requestProps?: any;
	confirmationProps?: ModalFuncProps;
}

export interface FetchButtonProps extends Omit<LoadingButtonProps<any>, 'actionFunction'> {
	fetchUrl: string;
	fetchData?: any;
	confirmationProps?: ModalFuncProps;
	method?: FetchMethod;
	defaultErrorMessage?: string;
}

interface FetchError {
	error: string;
}

interface FetchResponse {
	success: number;
	response: string;
}

const { success, error } = Modal;

const LoadingButton: <T>(props: LoadingButtonProps<T>) => React.ReactElement<T> = <T, >(props: LoadingButtonProps<T>) => {
	const {
		actionFunction,
		beforeAction,
		successMessage,
		showActionErrorMessage = false,
		children,
		...rest
	} = props;

	const defaultOnSuccess = () => success({
		title: 'Success',
		content: successMessage || 'Success.',
	});

	const defaultOnError = (err) => error({
		title: 'Error',
		content: showActionErrorMessage ? err : 'Sorry, something went wrong.',
	});

	const onSuccess = props.onSuccess || defaultOnSuccess;
	const onError = props.onError || defaultOnError;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const callAction = () => {
		setIsLoading(true);

		actionFunction()
			.then((res: T) => {
				onSuccess(res, defaultOnSuccess);
			})
			.catch((err: string) => {
				onError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (beforeAction) {
			if (beforeAction(callAction, event)) {
				callAction();
			}
		} else {
			callAction();
		}
	};

	return <>
		<Button
			isLoading={isLoading}
			{...rest}
			onClick={onClick}
		>
			{children}
		</Button>
	</>;
};

export const RequestButton: <T>(props: RequestButtonProps<T>) => React.ReactElement<T> = <T, >(props: RequestButtonProps<T>) => {
	const {
		requestType,
		requestProps = {},
		confirmationProps,
		children,
		...rest
	} = props;
	const request = UseRequest();

	const beforeActionFunction = confirmationProps
		? (action): boolean => {
			Modal.confirm({
				content: '',
				okText: 'Yes',
				okType: 'primary',
				cancelText: 'No',
				...confirmationProps,
				onOk: action,
			});
			return false;
		}
		: () => true;

	return <>
		<LoadingButton
			{...rest}
			beforeAction={rest.beforeAction || beforeActionFunction}
			actionFunction={() => request<T>(requestType, requestProps)}
			showActionErrorMessage
		>
			{children}
		</LoadingButton>
	</>;
};

export const isFetchError = (error): error is FetchError => {
	return (error as FetchError).error !== undefined
		&& `${(error as FetchError).error}`.toLowerCase() !== 'load failed'; // try to prevent showing error on IPad when file loaded
};

const isFetchResponse = (message): message is FetchResponse => {
	return (message as FetchResponse).success !== undefined
		&& (message as FetchResponse).response !== undefined;
};

export const handleFetchResponse = (response, defaultErrorMessage: string = '') => {
	const contentType = response.headers.get('content-type');

	if (contentType && contentType.includes('application/json')) {
		return response.json().then((res) => {
			if (response.status === 200) {
				if (isFetchResponse(res)) {
					if (res.success === 1) {
						return res.response;
					}

					throw res.response;
				}

				return res;
			}

			if (isFetchError(res)) {
				throw res.error;
			}

			throw defaultErrorMessage || response.statusText;
		});
	}

	if (response.status === 200) {
		return response;
	}

	throw defaultErrorMessage || response.statusText;
};

export const FetchButton: (props: FetchButtonProps) => React.ReactElement = (props: FetchButtonProps) => {
	const {
		fetchUrl,
		fetchData = {},
		confirmationProps,
		method,
		children,
		beforeAction = () => true,
		defaultErrorMessage,
		...rest
	} = props;

	const beforeActionFunction = confirmationProps
		? (action): boolean => {
			Modal.confirm({
				content: '',
				okText: 'Yes',
				okType: 'primary',
				cancelText: 'No',
				...confirmationProps,
				onOk: action,
			});
			return false;
		}
		: beforeAction;

	const actionFunction = (): Promise<any> => {
		return fetch(fetchUrl, {
			credentials: 'same-origin',
			method: method === FetchMethod.POST ? 'POST' : 'GET',
			headers: {
				'Content-type': 'application/json; charset=utf-8',
			},
			body: method === FetchMethod.POST ? JSON.stringify(fetchData) : null,
		}).then((response) => {
			return handleFetchResponse(response, defaultErrorMessage);
		});
	};

	return <>
		<LoadingButton
			{...rest}
			beforeAction={beforeActionFunction}
			actionFunction={actionFunction}
			showActionErrorMessage
		>
			{children}
		</LoadingButton>
	</>;
};

export default LoadingButton;
