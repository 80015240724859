import * as React from 'react';

import { FieldArray, Formik, FormikProps } from 'formik';
import Radio from 'antd/lib/radio';
import Select from 'antd/lib/select';
import Collapse from 'antd/lib/collapse';
import * as Yup from 'yup';

import Loading from '@common/react/components/Core/LoadingProvider/Loader';
import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import { PreventDefaultButton } from '@common/react/components/Forms/Button';
import { request } from '@common/react/components/Api';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';

import { BaseTestSession } from '@commonTuna/react/objects/BaseTestSession';
import { QuestionType } from '@commonTuna/react/objects/BaseDisease';
import { AnswersHistoryButton } from '@commonTuna/react/components/UI/AnswersHistoryButton/AnswersHistoryButton';
import { BasePatient } from '@commonTuna/react/objects/BasePatient';
import CopyForward from '@commonTuna/react/components/UI/QuestionnaireForm/CopyForward';
import PatientSignature from '@commonTuna/react/components/UI/QuestionnaireForm/PatientSignature';
import { useMobileView } from '@commonTuna/react/utils/useMobileView';

import '@commonTuna/react/scss/components/questionnaireForm.scss';

interface QueryParams {
	inviteId: number;
	appointmentId: number | null;
	diseaseId: number;
}

interface ComponentProps {
	session: BaseTestSession | null;
	afterSubmit?: () => void;
	onCancel?: () => void;
	queryParams?: QueryParams;
	enableSave?: boolean;
	hasSign?: boolean;
	showAnswersHistory?: boolean;
	patient?: BasePatient;
	nextBtnOnClick?: () => void;
	saveBtnCaption?: string;
	setSession?: (props: BaseTestSession) => void;
	setHasSign?: (props: boolean) => void;
	copyForwardDisabled?: boolean;
	signatureSrc?: string | undefined;
	setSignatureSrc?: (src: string | undefined) => void;
	testSessions?: Array<BaseTestSession>;
	updateWizardData?: (data) => void;
	fromPortal?: boolean;
	setForm?: (form: FormikProps<any> | null) => void;
	showSave?: boolean;
	beforeForm?: (formikBug: FormikProps<any>) => React.ReactNode;
	afterCopy?: (testSession: BaseTestSession) => void;
	readonly?: boolean;
}

interface ComponentState {
	isLoading: boolean;
	success: boolean;
	error: string;
}

const InitialState = {
	isLoading: false,
	success: false,
	error: '',
};

const validationSchema = Yup.object().shape({
	questions: Yup.array()
		.of(
			Yup.object().shape({
				answerNumber: Yup.mixed().test('is-not-empty', 'Required field!', (value) => value !== null && value !== ''),
			}),
		),
});

type FormValues = BaseTestSession;

const { Option } = Select;

interface QuestionnaireFormHandlerProps {
	session: BaseTestSession | null;
	formikBug: FormikProps<FormValues>;
	setForm?: (form: FormikProps<any> | null) => void;
}

const diagnosisAnswerClassName = 'diagnosis-answer diagnosis-answer_answerBool';

const QuestionnaireFormHandler: React.FC<QuestionnaireFormHandlerProps> = (props) => {
	const { formikBug, session, setForm } = props;
	React.useEffect(() => {
		if (session) {
			formikBug.setValues({ ...session });
		}
	}, [session]);

	React.useEffect(() => {
		if (setForm) {
			setForm(formikBug);
		}
	}, [setForm]);

	return <></>;
};

const QuestionnaireForm: React.FC<ComponentProps> = ({
	session,
	afterSubmit,
	onCancel,
	queryParams = {},
	enableSave = false,
	hasSign,
	showAnswersHistory = false,
	patient,
	nextBtnOnClick,
	saveBtnCaption = 'Save',
	setSession,
	setHasSign,
	copyForwardDisabled,
	signatureSrc,
	setSignatureSrc,
	testSessions,
	updateWizardData,
	fromPortal = true,
	setForm,
	showSave = true,
	beforeForm,
	afterCopy,
	readonly,
}) => {
	const [state, setState] = React.useState<ComponentState>(InitialState);

	const isMobile = useMobileView();

	const onSubmit = (values: FormValues) => {
		if (readonly) {
			return;
		}
		setState((prevState) => ({
			...prevState,
			isLoading: true,
		}));

		const questions = values.questions.filter((question) => {
			const savedDataQuestion = session?.questions.find((q) => q.id === question.id);
			if (savedDataQuestion) {
				return JSON.stringify(question) !== JSON.stringify(savedDataQuestion);
			}
			return false;
		}).map((testAnswer) => {
			const parsedAnswerNumber = parseFloat(testAnswer.answerNumber);
			return {
				...testAnswer,
				answerNumber: parsedAnswerNumber,
				question: null,
				testSession: null,
				choices: testAnswer.choices,
			};
		});

		request<BaseTestSession, BaseUser, BaseApplicationState<BaseUser>>('testSession', {
			...values,
			questions,
			...queryParams,
			answered: true,
		}).then(() => {
			setState((prevState) => ({
				...prevState,
				success: true,
			}));
			afterSubmit && afterSubmit();
		}).catch((err) => {
			setState((prevState) => ({
				...prevState,
				error: err,
			}));
		}).finally(() => {
			setState((prevState) => ({
				...prevState,
				isLoading: false,
			}));
			setTimeout(() => {
				setState(InitialState);
			}, 3000);
		});
	};

	const handleClose = (): void => {
		onCancel && onCancel();
	};

	if (!session) {
		return <Loading />;
	}

	const updateTestSessionsData = (formValues: FormValues, index: number, property: string, value: any) => {
		if (testSessions && updateWizardData) {
			const tempTestSession = formValues;
			const idx = testSessions.findIndex((testSession) => testSession.id === session.id);
			const tempTestSessions = testSessions;

			if (idx >= 0) {
				if (property === 'answerText') {
					tempTestSession.questions[index].answerText = value || null;
				} else if (property === 'answerNumber') {
					tempTestSession.questions[index].answerNumber = value;
				} else if (property === 'answerBool') {
					tempTestSession.questions[index].answerBool = value;
				} else if (property === 'choices') {
					tempTestSession.questions[index].choices = value;
				} else if (property === 'note') {
					tempTestSession.questions[index].note = value || null;
				}
				tempTestSessions[idx] = tempTestSession;
				updateWizardData({
					testSessions: tempTestSessions,
				});
			}
		}
	};

	return <div>
		<Formik
			initialValues={{
				...session,
				questions: session.questions,
			}}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			enableReinitialize
		>
			{(formikBag: FormikProps<FormValues>) => {
				const saveBtnDisabled = JSON.stringify(formikBag.values.questions) === JSON.stringify(session.questions);

				return <>
					{beforeForm && beforeForm(formikBag)}
					<QuestionnaireFormHandler session={session} formikBug={formikBag} setForm={setForm} />
					<FieldArray
						name="questions"
						render={(arrayHelpers) => {
							const questionsLength = formikBag.values.questions.length;
							const oddAnswer = questionsLength % 2 === 1;
							const questions = formikBag.values.questions.filter((testAnswer) => !!testAnswer);

							return <div>
								{questions.map((testAnswer, index) => {
									const { questionType } = testAnswer;
									return (
										<div
											className={`panel panel-default question-panel ${
												oddAnswer ? 'question-panel_odd' : ''}`}
											key={testAnswer.id}
										>
											<div className="panel-heading">
												{testAnswer.questionText}
												{showAnswersHistory
													&& <div className="pull-right">
														<AnswersHistoryButton
															patient={patient}
															questionId={testAnswer.questionId
																? testAnswer.questionId : -1}
														/>
													</div>
												}
											</div>
											<div className="panel-body">
												{questionType === QuestionType.Text
													&& <div className="qustionaire-text-area">
														<FormikField
															containerClassName=""
															fieldName={`questions.${index}.answerText`}
															render={({ field, form }) => {
																return <textarea
																	disabled={readonly}
																	className="form-control"
																	id={field.name}
																	{...field}
																	value={field.value === null ? '' : field.value}
																	onChange={(e) => {
																		form.setFieldValue(
																			field.name,
																			e.target.value || null,
																		);
																		updateTestSessionsData(
																			formikBag.values,
																			index,
																			'answerText',
																			e.target.value,
																		);
																	}}
																/>;
															}}
														/>
													</div>
												}
												{questionType === QuestionType.Number
													&& <div className="qustionaire-text-area">
														<FormikField
															containerClassName=""
															fieldName={`questions.${index}.answerNumber`}
															render={({ field, form }) => {
																return (<input
																	disabled={readonly}
																	className="form-control"
																	type="number"
																	value={field.value}
																	onChange={(e) => {
																		form.setFieldValue(field.name, e.target.value);
																		updateTestSessionsData(
																			formikBag.values,
																			index,
																			'answerNumber',
																			e.target.value,
																		);
																	}}
																/>);
															}}
														/>
													</div>
												}
												{questionType === QuestionType.YesNo
													&& <FormikField
														containerClassName=""
														fieldName={`questions.${index}.answerBool`}
														render={({ field, form }) => (
															<Radio.Group
																disabled={readonly}
																style={{ width: '100%' }}
																onChange={(e) => {
																	form.setFieldValue(field.name, e.target.value);
																	updateTestSessionsData(
																		formikBag.values,
																		index,
																		'answerBool',
																		e.target.value,
																	);
																}}
																value={field.value}
															>
																<Radio
																	value
																	className={diagnosisAnswerClassName}
																>
																	Yes
																</Radio>
																<Radio
																	value={false}
																	className={diagnosisAnswerClassName}
																>
																	No
																</Radio>
															</Radio.Group>
														)}
													/>
												}
												{(questionType === QuestionType.Single
														|| questionType === QuestionType.Multiple)
													&& <FormikField
														containerClassName=""
														fieldName={`questions.${index}.choices`}
														render={({ field, form }) => {
															const isMultiple = questionType === QuestionType.Multiple;
															let choiceValue: number | Array<number> | undefined;
															if (field.value) {
																choiceValue = isMultiple
																	? field.value
																		.filter((ch) => ch.selected)
																		.map((ch) => ch.id)
																	: field.value.filter((ch) => ch.selected)[0]?.id;
															}

															const setValue = (value) => {
																const resultValue = field.value.map((ch) => ({
																	...ch,
																	selected: isMultiple
																		? value.includes(ch.id) : ch.id === value,
																}));
																form.setFieldValue(field.name, resultValue);
																updateTestSessionsData(
																	formikBag.values,
																	index,
																	'choices',
																	resultValue,
																);
															};
															return <Select
																disabled={readonly}
																mode={isMultiple ? 'multiple' : undefined}
																style={{ width: '100%' }}
																onChange={(value) => setValue(value)}
																value={choiceValue}
																placeholder="Select the answer"
															>
																{testAnswer.choices.map((choice) => (
																	<Option key={choice.id} value={choice.id}>
																		<div
																			style={{
																				maxWidth: '100%',
																				overflow: 'hidden',
																				textOverflow: 'ellipsis',
																				whiteSpace: 'nowrap',
																			}}
																			title={choice.answerText}
																		>
																			{choice.answerText}
																		</div>
																	</Option>
																))}
															</Select>;
														}}
													/>
												}
											</div>
											<div className="question-panel__notes">
												<Collapse
													defaultActiveKey={testAnswer.note
													&& testAnswer.note !== '' ? ['1'] : undefined}
													items={[
														{
															key: '1',
															label: 'Notes',
															children: <>
																<FormikField
																	containerClassName="qustionaire-text-area"
																	fieldName={`questions.${index}.note`}
																	render={({ field, form }) => {
																		return <textarea
																			disabled={readonly}
																			className="form-control notes"
																			id={field.name}
																			{...field}
																			value={field.value === null ? '' : field.value}
																			onChange={(e) => {
																				form.setFieldValue(
																					field.name,
																					e.target.value || null,
																				);
																				updateTestSessionsData(
																					formikBag.values,
																					index,
																					'note',
																					e.target.value,
																				);
																			}}
																		/>;
																	}}
																/>
															</>,
														},
													]}
												/>
											</div>
										</div>
									);
								})}
							</div>;
						}}
					/>
					{enableSave
						? <div className="form-group text-center questionnaire-bottom-buttons-wrapper">
							<span className="flex-wrapper">
								{showSave && <PreventDefaultButton
									title={saveBtnDisabled
										? 'All answers are saved'
										: hasSign ? `${saveBtnCaption}` : 'Patient Sign is required'
									}
									isLoading={state.isLoading}
									onClick={formikBag.submitForm}
									className="btn btn-success mt10 mr10"
									disabled={readonly || saveBtnDisabled || !hasSign}
								>
									{saveBtnCaption}
								</PreventDefaultButton>}
								{nextBtnOnClick
									&& <PreventDefaultButton
										isLoading={state.isLoading}
										onClick={nextBtnOnClick}
										className="btn btn-primary mt10"
									>
										Skip
									</PreventDefaultButton>
								}
							</span>
							{session
								&& (
									<span
										className={`questionnaire-bottom-right-buttons flex-wrapper ${
											(showSave || nextBtnOnClick) ? 'mt10' : ''}`}
									>
										{(!fromPortal || !isMobile)
											&& <CopyForward
												session={session}
												afterCopy={(res) => {
													setSession && setSession(res);
													setSignatureSrc && setSignatureSrc(res.files?.list[0]?.file?.src);
													afterCopy && afterCopy(res);
												}}
												caption="Copy Forward"
												disabled={readonly || copyForwardDisabled}
												btnClassName="btn btn-primary mr10"
											/>
										}
										{session.disease?.patientSign
											&& <PatientSignature
												objectId={session.id}
												src={signatureSrc}
												afterSign={(file) => {
													setHasSign && setHasSign(Boolean(file));
													setSignatureSrc && setSignatureSrc(file);
												}}
												caption="Patient Sign"
												fromPortal={fromPortal}
											/>
										}
									</span>
								)
							}
						</div>
						: readonly
							? null
							: (
								<div className="text-center">
									<PreventDefaultButton
										isLoading={state.isLoading}
										onClick={handleClose}
										className="btn btn-danger mt10"
									>
										Close
									</PreventDefaultButton>
								</div>
							)
					}
					<div>
						{state.success && <div className="alert alert-success">Successfully saved</div>}
						{state.error && <div className="alert alert-danger">{state.error}</div>}
					</div>
				</>;
			}}
		</Formik>
	</div>;
};

export default QuestionnaireForm;
