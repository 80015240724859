import * as React from 'react';

import { FormikProps } from 'formik';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import { FileInterface } from '@common/typescript/objects/FileInterface';
import { BaseUser } from '@common/react/objects/BaseUser';
import { WithFile } from '@common/typescript/objects/WithFile';

import CopyForward from '@commonTuna/react/components/UI/QuestionnaireForm/CopyForward';
import PatientSignature from '@commonTuna/react/components/UI/QuestionnaireForm/PatientSignature';
import { ClearFieldsButton } from '@commonTuna/react/components/UI/ClearFieldsButton/ClearFieldsButton';
import { RecreateTestSessionButton } from '@commonTuna/react/components/UI/RecreateTestSessionButton/RecreateTestSessionButton';
import { UpdateTestSessionButton } from '@commonTuna/react/components/UI/UpdateTestSessionButton/UpdateTestSessionButton';
import { BaseTestSession, TestSessionButtons } from '@commonTuna/react/objects/BaseTestSession';
import DoctorDiseaseSettings from '@commonTuna/react/components/UI/DoctorDiseaseSettings/DoctorDiseaseSettings';
import { useMobileView } from '@commonTuna/react/utils/useMobileView';

interface QuestionnaireButtonsProps {
	testSession: BaseTestSession;
	containerClassName?: string;
	testSessionButtons?: Array<TestSessionButtons>;
	signatureSrc?: string;
	setSignatureSrc?: (src: string) => void;
	setHasSign?: (props: boolean) => void;
	copyForwardDisabled?: boolean;
	setCopyForwardDisabled?: (disabled: boolean) => void;
	form?: FormikProps<any> | null;
	doctorId?: number;
	updateTestSession?: (testSession: BaseTestSession, updateSavedData?: boolean) => void;
	updateTestSessionAfterSign?: boolean;
	afterRecreation?: (testSession: BaseTestSession) => void;
	afterUpdate?: (testSession: BaseTestSession) => void;
	afterReset?: (testSession: BaseTestSession) => void;
	afterCopy?: (testSession: BaseTestSession) => void;
	afterSign?: (testSession: BaseTestSession) => void;
	fromPortal?: boolean;
	readonly?: boolean;
}

const QuestionnaireButtons: React.FC<QuestionnaireButtonsProps> = ({
	testSession,
	containerClassName = 'pull-right',
	testSessionButtons,
	signatureSrc,
	setSignatureSrc = () => undefined,
	setHasSign = () => undefined,
	copyForwardDisabled = true,
	setCopyForwardDisabled = () => undefined,
	form = null,
	doctorId = 0,
	updateTestSession = () => undefined,
	updateTestSessionAfterSign = true,
	afterRecreation = () => undefined,
	afterUpdate = () => undefined,
	afterReset = () => undefined,
	afterCopy = () => undefined,
	afterSign = () => undefined,
	fromPortal = true,
	readonly,
}) => {
	const isMobile = useMobileView();

	return <div className={containerClassName} style={{ marginBottom: 10 }}>
		{testSession
			&& <div>
				{testSessionButtons?.some((b) => b === TestSessionButtons.DiseaseEditor)
					&& <LinkWithPrevLocation
						to={{
							pathname: `/${testSession.disease?.global
								? 'global-question-editor'
								: 'medical-question-editor'}/${testSession.diseaseId}`,
							search: 'mode=view',
						}}
						className="btn btn-default"
						title="Disease Editor"
					>
						<i className="fa fa-eye" />
					</LinkWithPrevLocation>
				}
				{!readonly && testSessionButtons?.some((b) => b === TestSessionButtons.Recreate)
					&& <RecreateTestSessionButton
						testSession={testSession}
						afterRecreation={(res) => {
							setSignatureSrc(res.files?.list[0]?.file?.src);
							setCopyForwardDisabled(!res.oldTestSessionId || (res.oldTestSessionId <= 0));
							updateTestSession(res);

							afterRecreation(res);
						}}
					/>
				}
				{!readonly && testSessionButtons?.some((b) => b === TestSessionButtons.Update)
					&& <UpdateTestSessionButton
						testSession={testSession}
						hasSignature={!!signatureSrc}
						afterSubmit={(res) => {
							setSignatureSrc(res.files?.list[0]?.file?.src);
							setCopyForwardDisabled(!res.oldTestSessionId || (res.oldTestSessionId <= 0));
							updateTestSession(res, true);

							afterUpdate(res);
						}}
					/>
				}
				{!readonly && testSessionButtons?.some((b) => b === TestSessionButtons.Clear)
					&& <ClearFieldsButton
						form={form}
						nextValues={{
							...testSession,
							questions: testSession.questions.map((question) => ({
								...question,
								answerText: '',
								answerBool: false,
								answerNumber: '0',
								choices: question.choices.map((choice) => ({
									...choice,
									selected: false,
								})),
								note: '',
							})),
						}}
						afterReset={(nextValues) => {
							if (nextValues) {
								const res = { ...nextValues } as BaseTestSession;
								updateTestSession(res);

								afterReset(res);
							}
						}}
					/>
				}
				{!readonly && <CopyForward
					session={testSession}
					afterCopy={(res) => {
						updateTestSession(res);
						setSignatureSrc(res.files?.list[0]?.file?.src);
						afterCopy(res);
					}}
					disabled={copyForwardDisabled}
					caption={(!fromPortal || !isMobile) ? undefined : 'Copy Forward'}
				/>}
				{!readonly && testSession.disease?.patientSign
					&& <PatientSignature
						objectId={testSession.id}
						disabled={!testSession.disease?.patientSign}
						src={signatureSrc}
						afterSign={(src) => {
							setHasSign(Boolean(src));
							setSignatureSrc(src);

							if (updateTestSessionAfterSign) {
								if (testSession.files?.list[0]?.file) {
									testSession.files.list[0].file.src = src;
								} else {
									testSession.files.list.push({
										file: {
											src,
										} as FileInterface<BaseUser>,
									} as WithFile<BaseTestSession, BaseUser>);
									testSession.files.count += 1;
								}
								updateTestSession(testSession);
							}

							afterSign(testSession);
						}}
						fromPortal={fromPortal}
					/>
				}
				{!readonly && testSessionButtons?.some((b) => b === TestSessionButtons.DoctorDiseaseSettings)
					&& <DoctorDiseaseSettings
						key={testSession.id}
						diseaseId={testSession.diseaseId}
						doctorId={doctorId}
					/>
				}
			</div>
		}
	</div>;
};

export default QuestionnaireButtons;
