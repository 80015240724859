import React from 'react';

import { useSelector } from 'react-redux';

import moment from 'moment';

import Radio, { RadioChangeEvent, RadioGroupProps } from 'antd/lib/radio';
import Segmented from 'antd/lib/segmented';

import { DashboardTimeInterval, intervals } from '@commonTuna/react/components/Utils';
import '@commonTuna/react/scss/components/timeIntervalRadio.scss';

import { ApplicationState } from '@app/store';

interface IPrors extends RadioGroupProps {
	range: [number, number] | null;
	dateIntervalChange: (data: [number | null, number | null], partition: number) => void;
	setPartition?: (index: number) => void;
	except?: Array<DashboardTimeInterval>;
	utc?: boolean;
	utcOffset?: boolean;
	customGetIntervalFromRange?: (range: [number, number] | null, utcOffset: string | undefined, utc?: boolean) => DashboardTimeInterval;
	disableChangeDate?: boolean;
	useSegmented?: boolean;
}

const TimeIntervalInfo = {
	[DashboardTimeInterval.Day]: [DashboardTimeInterval.Day, 'day'],
	[DashboardTimeInterval.Week]: [DashboardTimeInterval.Day, 'week'],
	[DashboardTimeInterval.Month]: [DashboardTimeInterval.Week, 'month'],
	[DashboardTimeInterval.Quarter]: [DashboardTimeInterval.Month, 'quarter'],
	[DashboardTimeInterval.Year]: [DashboardTimeInterval.Quarter, 'year'],
	[DashboardTimeInterval.All]: [DashboardTimeInterval.Year, ''],
};

export function getIntervalTimes(type, count, userUtcOffset?: string, utc?: boolean): [number | null, number | null] {
	if (type === DashboardTimeInterval.All) {
		return [null, null];
	}

	const intervalType = TimeIntervalInfo[type][1];
	const start = userUtcOffset
		? moment().utcOffset(userUtcOffset).add(count, intervalType).startOf(intervalType)
		: utc
			? moment().utc().add(count, intervalType).startOf(intervalType)
			: moment().add(count, intervalType).startOf(intervalType);
	const end = userUtcOffset
		? moment().utcOffset(userUtcOffset).add(count, intervalType).endOf(intervalType)
		: utc
			? moment().utc().add(count, intervalType).endOf(intervalType)
			: moment().add(count, intervalType).endOf(intervalType);

	return [+(start), +(end)];
}

export function getIntervalFromRange(range: [number, number] | null, userUtcOffset?: string, utc?: boolean): DashboardTimeInterval {
	if (!range) {
		return DashboardTimeInterval.All;
	}

	return intervals.slice(0, intervals.length - 1).find(({ interval, caption }) => {
		const intervalType = TimeIntervalInfo[interval][1] as any;
		const [start, end] = range;

		return userUtcOffset
			? moment(start).utcOffset(userUtcOffset).valueOf() === moment(start).utcOffset(userUtcOffset).startOf(intervalType).valueOf()
				&& moment(end).utcOffset(userUtcOffset).valueOf() === moment(start).utcOffset(userUtcOffset).endOf(intervalType).valueOf()
			: utc
				? moment(start).utc().valueOf() === moment(start).utc().startOf(intervalType).valueOf()
					&& moment(end).utc().valueOf() === moment(start).utc().endOf(intervalType).valueOf()
				: moment(start).valueOf() === moment(start).startOf(intervalType).valueOf()
					&& moment(end).valueOf() === moment(start).endOf(intervalType).valueOf();
	})?.interval || DashboardTimeInterval.All;
}

const getValue = (value: DashboardTimeInterval, range: [number, number] | null, userUtcOffset?: string, utc?: boolean): number => {
	if (range && value !== DashboardTimeInterval.All) {
		const type = TimeIntervalInfo[value][1];

		return userUtcOffset
			? -moment().utcOffset(userUtcOffset).startOf(type as any).diff(range[0], type as any)
			: utc
				? -moment().utc().startOf(type as any).diff(range[0], type as any)
				: -moment().startOf(type as any).diff(range[0], type as any);
	}
	return 0;
};

const TimeIntervalRadio: React.FC<IPrors> = ({
	onChange,
	className,
	dateIntervalChange,
	setPartition,
	range,
	except,
	utc = false,
	utcOffset = true,
	customGetIntervalFromRange,
	disableChangeDate,
	useSegmented = true,
}) => {
	const userUtcOffset = utcOffset
		? useSelector((state: ApplicationState) => (
			state.login?.user && state.login?.user.baseUtcOffset
				? state.login?.user.baseUtcOffset : undefined))
		: undefined;
	const [interval, setInterval] = React.useState<DashboardTimeInterval>(
		customGetIntervalFromRange
			? customGetIntervalFromRange(range, userUtcOffset, utc)
			: getIntervalFromRange(range, userUtcOffset, utc),
	);
	const newInterval = customGetIntervalFromRange
		? customGetIntervalFromRange(range, userUtcOffset, utc)
		: getIntervalFromRange(range, userUtcOffset, utc);

	React.useEffect(() => {
		setInterval(newInterval);
	}, [newInterval]);

	const handleDate = (value, count, userUtcOffset?: string, utc?: boolean) => {
		dateIntervalChange(getIntervalTimes(value, count, userUtcOffset, utc), TimeIntervalInfo[value][0]);
	};

	const handleChange: (e: RadioChangeEvent) => void = (e) => {
		const value = e.target.value;
		onChange && onChange(e);
		setInterval(value);
		setPartition && setPartition(TimeIntervalInfo[value][0]);
		handleDate(value, 0, userUtcOffset, utc);
	};

	const addDateStart = () => {
		const add = getValue(interval, range, userUtcOffset, utc);
		handleDate(interval, add + 1, userUtcOffset, utc);
	};

	const subDateStart = () => {
		const add = getValue(interval, range, userUtcOffset, utc);
		handleDate(interval, add - 1, userUtcOffset, utc);
	};

	let showIntervals = intervals;

	if (except && except.length > 0) {
		showIntervals = intervals.filter((q) => !except.includes(q.interval));
	}

	return (
		<div className={`radio-with-range ${className || ''}`}>
			{useSegmented
				? (
					<Segmented
						value={+interval}
						onChange={(value) => handleChange({ target: { value } } as RadioChangeEvent)}
						options={showIntervals.map((item) => ({
							label: <span>
								{!disableChangeDate && item.interval !== DashboardTimeInterval.All && <>
									<button
										type="button"
										className="radio-button-arrow radio-button-up"
										onClick={addDateStart}
									>
										<span className="angle-wrap">
											<i className="fa fa-angle-up" />
										</span>
									</button>
									<button
										type="button"
										className="radio-button-arrow radio-button-down"
										onClick={subDateStart}
									>
										<span className="angle-wrap">
											<i className="fa fa-angle-down" />
										</span>
									</button>
								</>}
								<span className="radio-label ant-radio-button-wrapper">{item.caption}</span>
							</span>,
							value: item.interval,
						}))}
					/>
				)
				: (
					<Radio.Group
						className="pull-right"
						name="archive"
						value={+interval}
						defaultValue={DashboardTimeInterval.All}
						onChange={handleChange}
					>
						{showIntervals.map((item) => (
							<Radio.Button key={item.interval} className="ratio-button-with-arrows" value={item.interval}>
								{!disableChangeDate && item.interval !== DashboardTimeInterval.All && <>
									<button
										type="button"
										className="radio-button-arrow radio-button-up"
										onClick={addDateStart}
									>
										<span className="angle-wrap">
											<i className="fa fa-angle-up" />
										</span>
									</button>
									<button
										type="button"
										className="radio-button-arrow radio-button-down"
										onClick={subDateStart}
									>
										<span className="angle-wrap">
											<i className="fa fa-angle-down" />
										</span>
									</button>
								</>}
								<span className="radio-label ant-radio-button-wrapper">{item.caption}</span>
							</Radio.Button>
						))}
					</Radio.Group>
				)
			}
		</div>
	);
};

export default TimeIntervalRadio;
