import React from 'react';
import MaskedInput from 'react-text-mask';

import { FieldProps } from 'formik';
import * as Yup from 'yup';

import { phoneRegexp } from '@common/react/utils/validationHelpers';

interface Props {
	placeholder?: string;
	fieldProps: FieldProps;
	className?: string;
	disabled?: boolean;
	allPhoneMask?: boolean;
}

const removePhoneMask = (value: string, withOne?: boolean) => (value
	? (withOne ? value.replace(/^\+1/, '') : value).replace(/[+\(\)\-\s_]/g, '') : '');

const removeMask = (value: string) => (value
	? value.replace(/\+(.) \((...)\) (...)-(...)/g, '$1$2$3$4') : '');

const allCountriesPhoneMask = ['+', /[1-9]/, ' ', '(', /[\d]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const allPhoneFormat = (phone: string | null | undefined): string => (phone
	? phone.replace(/^\+([1-9])([1-9]\d{2})(\d{3})(\d{4})$/, '+$1 ($2) $3-$4') : '');

export const emailOrPhoneValidator = Yup.string()
	.test(
		'is-valid',
		'Enter Valid Phone/Email',
		(value) => {
			const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

			const isValidEmail = emailRegex.test(value || '');
			const isValidPhone = phoneRegexp.test(value || '');

			return isValidEmail || isValidPhone;
		},
	);

const PhoneOrEmailInput: React.FC<Props> = ({
	placeholder = '',
	fieldProps: { field, form },
	className = 'form-control',
	allPhoneMask,
	disabled,
}) => {
	const value = React.useMemo(() => removeMask(field.value), [field.value]);

	const pipe = (value, t) => {
		const trimRawValue = t.rawValue.trim();
		if (['_', '+', '-', '(', ')'].includes(trimRawValue)) {
			return {
				value: '+1 (',
				indexesOfPipedChars: [0, 1, 2, 3, 4],
			};
		}
		const text = ['0', '1'].includes(trimRawValue) ? `+1 (${trimRawValue}` : value;
		const withoutPhoneMask = removePhoneMask(value);

		const hasPhoneMask = text.match(/^\+/);
		const textWithoutMinusMask = hasPhoneMask ? text.replace(/-/, '') : text;
		const hasPreviousValuePhoneMask = t.previousConformedValue?.match(/^\+/);

		return (withoutPhoneMask.match(/\D/) || value.trim() === '') && withoutPhoneMask
			? textWithoutMinusMask.replace(hasPreviousValuePhoneMask ? /[+\(\)\s_]/g : /[+\(\)\s]/g, '')
				.replace(/^1/, (value) => (hasPreviousValuePhoneMask ? '' : value))
			: text;
	};

	const handleChange = (e) => {
		const value = e.target.value;
		const withoutPhoneMask = removePhoneMask(value);
		form.setFieldValue(field.name, value && !(withoutPhoneMask.match(/\D/) || value.trim() === '')
			? `+${value.match(/^\+1/) && !allPhoneMask ? '' : 1}${withoutPhoneMask}` : value);
	};

	const mask = (value) => {
		const withoutPhoneMask = removePhoneMask(value);
		return (withoutPhoneMask.match(/\D/) || value.trim() === '') && value ? value.split('').map((i) => /./) : allCountriesPhoneMask;
	};

	return <MaskedInput
		mask={mask}
		className={className}
		placeholder={placeholder}
		pipe={pipe}
		guide
		id={field.name}
		onChange={handleChange}
		onBlur={field.onBlur}
		value={value}
		disabled={disabled}
	/>;
};

export default PhoneOrEmailInput;
