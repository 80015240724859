import * as React from 'react';

import Select, { SelectProps } from 'antd/lib/select';

export interface SelectFilterProps {
	additionalParams: any;
	param: string;
	afterChange?: (key, value) => void;
	disabled?: boolean;
	className?: string;
	onChange: (evt) => void;
	placeholder?: string;
	defaultValue?: string | number | null | Array<any>;
	selectProps?: SelectProps;
	currentValue?: any;
}

const SelectFilter: React.FunctionComponent<SelectFilterProps> = (props) => {
	const {
		additionalParams,
		param,
		children,
		afterChange,
		disabled,
		className,
		onChange,
		placeholder,
		defaultValue = -1,
		selectProps,
		currentValue,
	} = props;

	return (
		<div className={className || 'select-filter-component'}>
			<Select
				value={currentValue ?? additionalParams[param] ?? defaultValue}
				onChange={(value, option) => {
					const handleChangeEventElement = {
						currentTarget: {
							name: param,
							value,
							option,
						},
					};
					onChange(handleChangeEventElement);
					afterChange && afterChange(param, value);
				}}
				disabled={disabled}
				placeholder={placeholder}
				{...selectProps}
			>
				{children}
			</Select>
		</div>
	);
};

export default SelectFilter;
