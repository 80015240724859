import React from 'react';

import { BaseInvite } from '@commonTuna/react/objects/BaseInvite';
import QuestionnaireModal from '@commonTuna/react/components/UI/QuestionnaireForm/QuestionnaireModal';
import ConsentPdfModal from '@commonTuna/react/components/UI/ConsentForm/ConsentPdfModal';

import InvitesButtons from '@app/components/UI/FillForms/InvitesButtons';

interface ComponentProps {
	invites: Array<BaseInvite>;
	withInviteButtons?: boolean;
	loadInvites?: () => void;
	loadInvitesAfterLast?: boolean;
	startHidden?: boolean;
	btnClassName?: string;
}

const FillForms: React.FC<ComponentProps> = ({
	invites,
	withInviteButtons,
	loadInvites,
	loadInvitesAfterLast = false,
	startHidden = true,
	btnClassName,
}) => {
	const [visible, setVisible] = React.useState<boolean>(false);
	const [changeVisibleFlag, setChangeVisibleFlag] = React.useState<boolean>(false);
	const [activeInviteIndex, setActiveInviteIndex] = React.useState<number>(0);
	const [nextBtn, setNextBtn] = React.useState<boolean>(false);

	const changeVisible = (visible: boolean) => (setTimeout(() => setVisible(visible), 0));

	React.useEffect(() => {
		if (!withInviteButtons) {
			setActiveInviteIndex(0);
			changeVisible(true);
			setChangeVisibleFlag(true);
			setNextBtn(false);
		}
	}, []);

	React.useEffect(() => {
		if (!visible && changeVisibleFlag) {
			nextBtn ? setNextInvite() : setNextInviteWithSkip();
		}
	}, [visible]);

	const skipAnswered = (index: number) => {
		let resultIndex = index;
		while ((resultIndex >= 0) && (resultIndex < invites.length) && invites[resultIndex].answered) {
			resultIndex++;
		}
		if ((resultIndex >= 0) && (resultIndex < invites.length)) {
			setActiveInviteIndex(resultIndex);
			changeVisible(true);
		} else {
			setChangeVisibleFlag(false);
		}
	};

	const setNextInvite = () => {
		const nextIndex = (activeInviteIndex >= 0) ? activeInviteIndex + 1 : -1;
		if ((nextIndex >= 0) && (nextIndex < invites.length)) {
			setActiveInviteIndex(nextIndex);
			changeVisible(true);
		} else {
			setChangeVisibleFlag(false);
		}
	};

	const setNextInviteWithSkip = () => {
		const nextIndex = (activeInviteIndex >= 0) ? activeInviteIndex + 1 : -1;
		skipAnswered(nextIndex);
	};

	return (<>
		{invites?.length > 0
			&& <span onClick={(e) => e.stopPropagation()}>
				{withInviteButtons
					&& <InvitesButtons
						invites={invites}
						setActiveInviteIndex={setActiveInviteIndex}
						changeVisible={changeVisible}
						setChangeVisibleFlag={setChangeVisibleFlag}
						setNextBtn={setNextBtn}
						skipAnswered={skipAnswered}
						startHidden={startHidden}
						btnClassName={btnClassName}
					/>
				}
				{invites.map((invite, index) => {
					if (index === activeInviteIndex) {
						if (invite.diseaseId > 0) {
							return (
								<QuestionnaireModal
									key={invites[activeInviteIndex]?.id}
									item={invites[activeInviteIndex]}
									shouldSendInviteId
									showIsAnythingChangedModal
									visible={visible}
									setVisible={changeVisible}
									afterCancelled={() => {
										setChangeVisibleFlag(false);
									}}
									refreshAfterClose={() => {
										if (loadInvites && (!loadInvitesAfterLast || (activeInviteIndex === invites.length - 1))) {
											loadInvites();
										}
									}}
									nextBtn={nextBtn}
									saveBtnCaption="Save & Next"
								/>
							);
						}
						return (
							<ConsentPdfModal
								key={invites[activeInviteIndex]?.id}
								item={invites[activeInviteIndex]}
								patientId={invites[activeInviteIndex].patientId}
								shouldSendInviteId
								visible={visible}
								setVisible={changeVisible}
								afterSubmit={() => {
									if (loadInvites && (!loadInvitesAfterLast || (activeInviteIndex === invites.length - 1))) {
										loadInvites();
									}
								}}
								afterCancelled={() => {
									setChangeVisibleFlag(false);
								}}
								nextBtn={nextBtn}
								closeAfterAccept
								showCloseButton={false}
							/>
						);
					}
					return null;
				})}
			</span>
		}
	</>);
};

export default FillForms;
