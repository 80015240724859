import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Nullable } from '@common/typescript/objects/Nullable';
import { BaseUser } from '@common/typescript/objects/BaseUser';

import { BaseDisease } from '@commonTuna/react/objects/BaseDisease';
import { BaseAppointmentProcedure, BaseAppointment } from '@commonTuna/react/objects/BaseAppointment';
import { BaseTemplateSnapshot } from '@commonTuna/react/objects/BaseTemplateSnapshot';

export enum InviteStatus {
	Send,
	Open,
	Done,
}

export const InviteStatusNames = {
	[InviteStatus.Send]: 'Send',
	[InviteStatus.Open]: 'Open',
	[InviteStatus.Done]: 'Done',
};

export enum InviteType {
	All = 0,
	Template = 1,
	Disease = 2,
	Instruction = 3
}

export interface BaseInviteAppointmentProcedure extends WithDeleted {
	inviteId: number;
	invite: Nullable<BaseInvite>;

	appointmentProcedureId: number;
	appointmentProcedure: Nullable<BaseAppointmentProcedure>;
}

export interface BaseInvite extends WithDeleted {
	time: number;

	patientId: number;

	appointmentId: number;
	appointment: Nullable<BaseAppointment>;

	patientFileId: number;

	userId: number;
	user: Nullable<BaseUser>;

	templateSnapshotId: number;
	templateSnapshot: Nullable<BaseTemplateSnapshot>;

	diseaseId: number;
	disease: Nullable<BaseDisease>;

	name: string;
	status: InviteStatus;

	askAlways?: boolean;
	answered: boolean;

	isCompanyTemplate: boolean;

	inviteAppointmentProcedures: Array<BaseInviteAppointmentProcedure>;
}
