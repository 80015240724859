import * as React from 'react';

import Message from 'antd/lib/message';

import { FileInterface, FileType } from '@common/typescript/objects/FileInterface';

import AvatarWithColorbox, {
	OnAvatarUploadResult,
} from '@commonTuna/react/components/UI/AvatarWithColorbox/AvatarWithColorbox';

import { CardImageType } from '@app/objects/CardImageType';

interface ComponentProps {
	objectId: number;
	fileType: string;
	cardType: CardImageType;
	defaultScr?: string;
	className?: string;
	onUpload?: (src: string, cardType: CardImageType) => void;
}

export const CardBlock: React.FC<ComponentProps> = React.memo(({
	objectId,
	cardType,
	fileType,
	className = 'col-sm-6 text-center',
	onUpload,
	defaultScr,
}) => {
	const isFront = cardType === CardImageType.Front;

	const [src, setSrc] = React.useState<string>(defaultScr || '');

	React.useEffect(() => {
		setSrc(defaultScr || '');
	}, [defaultScr]);

	const update = (res: OnAvatarUploadResult, data?: FileInterface) => {
		setSrc(data?.src ?? '');
		onUpload && onUpload(data?.src ?? '', cardType);
	};

	return <div className={className}>
		<label>
			Card
			{isFront ? 'FRONT' : 'BACK'}
		</label>
		<div className="card-block__wrapper">
			<AvatarWithColorbox
				propertyOrigin="avatar"
				object={{ avatar: src, id: objectId }}
				update={update}
				infoMessage=""
				buttonCaption={`Add photo ${isFront ? 'Front' : 'Back'}`}
				fileType={FileType.File}
				type={fileType}
				additionalData={{
					cardImageType: cardType,
				}}
				removeRequest={`${fileType}ImageRemove`}
				removeData={{ src }}
				onError={(err) => {
					Message.error('Unable to remove file.');
					console.error(err);
				}}
			/>
		</div>
	</div>;
});
