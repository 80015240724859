import * as React from 'react';
import MaskedInput from 'react-text-mask';

import { FieldProps } from 'formik';

import '@common/react/scss/components/confirmationCode.scss';

interface Props {
	fieldProps: FieldProps;
	disabled?: boolean;
	mask?: any;
}

export const ConfirmationCodeInput: React.FC<Props> = ({
	fieldProps: { field },
	disabled,
	mask = [/\d/, /\d/, /\d/, /\d/],
}) => {
	return <div className="code-container">
		<MaskedInput
			className="form-control code-input"
			type="text"
			disabled={disabled}
			mask={mask}
			guide={false}
			id={field.name}
			{...field}
		/>
		<label className="code-underline" htmlFor="smsCode" />
	</div>;
};
