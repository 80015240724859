import * as React from 'react';
import CanvasDraw from '@win11react/react-canvas-draw';

import Modal from 'antd/lib/modal';

import Button from '@common/react/components/Forms/Button';

import { dataURLtoFile, getDataUrl } from '@commonTuna/react/utils/Utils';
import '@commonTuna/react/scss/components/patientSignature.scss';
import '@commonTuna/react/scss/components/consentFormWithSignature.scss';

interface OwnProps {
	objectId: number;
	disabled?: boolean;
	src?: string;
	afterSign?: (files: any) => void;
	fromPortal?: boolean;
	caption?: any;
}

const PatientSignature: React.FC<OwnProps> = (props) => {
	const canvasHeight = 185;
	const canvasBrushRadius = 2;

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [visible, setVisible] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<string>('');
	const canvas = React.useRef<CanvasDraw | null>(null);
	const parent = React.useRef<HTMLDivElement | null>(null);
	const [src, setSrc] = React.useState<string | undefined>(props.src);
	const [clearDisabled, setClearDisabled] = React.useState<boolean>(!props.src);

	React.useEffect(() => {
		setSrc(props.src);
		setClearDisabled(!props.src);
	}, [props.src]);

	const submitSignature = () => {
		const signatureDataUrl = getDataUrl(canvas.current);
		setMessage('');

		if (signatureDataUrl) {
			setIsLoading(true);

			const signatureImg = dataURLtoFile(signatureDataUrl, `signature_${Date.now()}.png`);

			const formData = new FormData();
			formData.append('objectId', props.objectId.toString());
			formData.append('signature', signatureImg);

			fetch('patient/signature', {
				method: 'POST',
				credentials: 'same-origin',
				body: formData,
			})
				.then((response) => response.json())
				.then((res/*: Message<FileInterface> */) => {
					if (res.success === 1) {
						setVisible(false);
						setSrc(res.response.src);
						props.afterSign && props.afterSign(res.response.src);
					} else {
						setMessage(res.response);
					}
				})
				.catch((res: string) => setMessage(res))
				.finally(() => setIsLoading(false));
		} else {
			setMessage('Signature is empty.');
		}
	};

	const afterClose = () => {
		setIsLoading(false);
		setMessage('');
	};

	const calcCanvasWidth = () => (parent.current && parseInt(window.getComputedStyle(parent.current).width, 10))
		|| undefined;

	const clearCanvas = () => {
		if (src) {
			setSrc('');
		}
		(canvas.current as CanvasDraw)?.clear();
		setClearDisabled(true);
	};

	const imgSrc: string | undefined = props.fromPortal ? `remote/${src}` : src;

	return <>
		<Button
			className={src ? 'btn btn-success' : 'btn btn-danger'}
			onClick={() => setVisible(true)}
			type="button"
			title="Patient sign"
			disabled={props.disabled}
		>
			{props.caption ? props.caption : <i className="fa fa-pencil-square" />}
		</Button>
		<Modal
			title="Patient Signature"
			width={800}
			footer={null}
			open={visible}
			onCancel={() => setVisible(false)}
			destroyOnClose
			afterClose={afterClose}
		>
			{src && src !== ''
				? <img src={imgSrc} alt="signature" />
				: <>
					<div className="sign-bg sign-border patient-signature-wrapper">
						<div className="sign-border consent-form-with-signature__canvas-parent" ref={parent}>
							<CanvasDraw
								brushRadius={canvasBrushRadius}
								ref={canvas}
								canvasWidth={calcCanvasWidth()}
								gridColor="rgba(150,150,150,0)"
								canvasHeight={canvasHeight}
								hideInterface
								className="canvas-wrapper"
								lazyRadius={0.5}
								onChange={() => setClearDisabled(false)}
							/>
						</div>
					</div>
					{message && <div className="alert alert-danger">{message}</div>}
				</>
			}
			<div className="patient-signature-buttons form-group mt10">
				{!src
					&& <div className="patient-signature-center-buttons">
						<Button
							key="sign"
							onClick={() => submitSignature()}
							isLoading={isLoading}
							className="btn btn-success"
							type="button"
						>
							Sign
						</Button>
						<Button
							key="cancel"
							onClick={() => setVisible(false)}
							className="btn btn-danger"
							type="button"
						>
							Cancel
						</Button>
					</div>
				}
				<div className="patient-signature-right-buttons">
					<Button
						className="btn btn-light"
						onClick={() => clearCanvas()}
						disabled={clearDisabled}
					>
						Clear
					</Button>
				</div>
			</div>
		</Modal>
	</>;
};

export default PatientSignature;
