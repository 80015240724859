import React, { Key } from 'react';

import Tooltip from 'antd/lib/tooltip';

import '@commonTuna/react/scss/components/listWithTooltip.scss';

interface Props {
	array: Array<any>;
	getValue: (item) => React.ReactNode;
	count?: number;
	getKey?: (item, index: number) => Key;
	getFirstValue?: (item) => React.ReactNode;
	getValueNode?: (item) => React.ReactNode;
	onClick?: (item: any) => any;
	containerClassName?: string;
	color?: string;
}

const ListWithTooltip: React.FC<Props> = ({
	array, getValue, getFirstValue, getValueNode, getKey, count = 1, onClick, ...props
}) => {
	if (array.length < 1) {
		return null;
	}

	const { containerClassName = '', color = '#fff' } = props;

	return <div className={containerClassName}>
		{array.slice(0, count).map((item, index) =>
			<React.Fragment key={(getKey && getKey(item, index)) || item.id}>
				{getFirstValue ? getFirstValue(getValue(item))
					: <div className="inline-select-item inline-select-item_sm">
						<span onClick={() => { onClick && onClick(item); }}>{getValue(item)}</span>
					</div>}
			</React.Fragment>)
		}
		{array.length > count && (
			<Tooltip
				color={color}
				overlayClassName="list-tooltip"
				title={array.slice(count).map((item, index) =>
					<React.Fragment key={(getKey && getKey(item, index)) || item.id}>
						{getValueNode ? getValueNode(getValue(item))
							: <div className="inline-select-item inline-select-item_sm" key={item.id}>
								<span onClick={() => { onClick && onClick(item); }}>{getValue(item)}</span>
							</div>}
					</React.Fragment>)}
			>
				<span className="inline-select-item inline-select-item_sm">
					+
					{array.slice(count).length}
					...
				</span>
			</Tooltip>
		)}
	</div>;
};

export default ListWithTooltip;
