import * as React from 'react';

interface AlertMessageProps {
	message?: string;
	alertClass?: string;
}

export const AlertMessage: React.FC<AlertMessageProps> = ({ message = '', alertClass = 'danger' }) => {
	return message ? <div className="alert alert-danger">{message}</div> : null;
};
