import { cropImage, isCanvasBlank } from '@commonTuna/react/utils/canvasUtils';

export const getParentWidth = (parentSelector: string): number | undefined => {
	if (typeof document !== 'undefined' && typeof window !== 'undefined') {
		const parentEl = document.querySelector(parentSelector);
		if (!parentEl) {
			return;
		}

		const parentStyle = window.getComputedStyle(parentEl, null);
		if (!parentStyle.width) {
			return;
		}

		return parseInt(parentStyle.width, 10)
			- parseInt(parentStyle.paddingLeft || '', 10)
			- parseInt(parentStyle.paddingRight || '', 10);
	}
};

export const getDataUrl = (element: any) => {
	if (!element || isCanvasBlank(element.canvas.drawing)) {
		return null;
	}

	return cropImage(element);
};

export function dataURLtoFile(dataurl: string, filename: string) {
	const arr = dataurl.split(',');
	const bstr = atob(arr[1]);
	const match = arr[0].match(/:(.*?);/);
	const mime = match ? match[1] : '';

	let n = bstr.length;

	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
}
